"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Input = _interopRequireDefault(require("./Input"));

var _default = _Input.default;
exports.default = _default;
module.exports = exports.default;