"use strict";

exports.__esModule = true;
exports.default = void 0;

var _utils = require("../utils");

var ModalContext = (0, _utils.createContext)(null);
var _default = ModalContext;
exports.default = _default;
module.exports = exports.default;