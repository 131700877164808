import camelizeStyleName from './camelizeStyleName';
import getComputedStyle from './getComputedStyle';
import hyphenateStyleName from './hyphenateStyleName';
export default (function (node, property) {
  if (property) {
    var value = node.style[camelizeStyleName(property)];

    if (value) {
      return value;
    }

    var styles = getComputedStyle(node);

    if (styles) {
      return styles.getPropertyValue(hyphenateStyleName(property));
    }
  }

  return node.style || getComputedStyle(node);
});