'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactChartjs2 = require('react-chartjs-2');

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = ['containerStyle'];
const PieChartV = (props) => {
    let _props = Object.assign({}, props);
    deleteKeys(_props, delete_keys);
    return (React.createElement(reactChartjs2.Pie, Object.assign({}, _props)));
};

exports.PieChartV = PieChartV;
