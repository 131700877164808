'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactImageBackground = require('react-image-background');
var reactNative = require('react-native');

//@ts-nocheck
const ids = require('short-id');
const BodyTemplateV = (props) => {
    const styles = {
        bodyContainer: {
            width: props.bodyWidth ? props.bodyWidth : '100%',
            margin: '0 auto',
            overFlowY: 'auto',
        },
        headerContainer: {
            background: '#fff',
            borderBottom: '1px solid #ccc',
            padding: '5px 10px',
        },
    };
    const renderBody = () => {
        const _body = [];
        _body.push(renderBodyHeader());
        _body.push(renderBodyContent());
        return _body;
    };
    const renderBodyHeader = () => {
        const { headerStyle } = props;
        return (React.createElement(reactFlexboxGridRs.FBGridV, { style: reactNative.StyleSheet.flatten([headerStyle, styles.headerContainer]), justify: 'space-between', align: 'middle' },
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 8 }, props.headerLeftData()),
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 8 }, props.headerRightData())));
    };
    const renderBodyContent = () => {
        console.log('bodyConent', props.renderContent);
        return (React.createElement(reactFlexboxGridRs.FBGridV, { style: reactNative.StyleSheet.flatten([
                props.bodyContainerStyle,
                styles.bodyContainer,
            ]), justify: 'space-between', align: 'middle' },
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 24 }, props.renderContent())));
    };
    return (React.createElement(reactImageBackground.ImageBackgroundV, { image: props.image, width: props.width, height: props.height, imageBackgroundStyle: props.imageBackgroundStyle }, renderBody()));
};

exports.BodyTemplateV = BodyTemplateV;
