'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var Button = _interopDefault(require('rsuite/lib/Button'));
var reactIconRs = require('react-icon-rs');
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var IconButton = _interopDefault(require('rsuite/lib/IconButton'));
var ButtonGroup = _interopDefault(require('rsuite/lib/ButtonGroup'));
var ButtonToolbar = _interopDefault(require('rsuite/lib/ButtonToolbar'));

//@ts-nocheck
var ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const buttonMapper = { buttonStyle: 'style' };
const delete_button_keys = ['containerStyle', 'onPress',
    'id', 'icon', 'buttonTitle', 'leftIcon', 'rightIcon'];
const styles = {
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconContainer: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    buttontoolbarStyle: {
        flexDirection: 'row'
    }
};
const ButtonV = (props) => {
    const buttonProps = Object.assign({}, props);
    renameKeys(buttonProps, buttonMapper);
    deleteKeys(buttonProps, delete_button_keys);
    const handleonPress = () => {
        props.onPress && props.onPress(props.id);
    };
    const renderIcon = (icon) => {
        if (icon) {
            return (React.createElement(reactIconRs.IconV, { icons: icon }));
        }
        return null;
    };
    const renderTitle = () => {
        if (props.buttonTitle) {
            return (React.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([props.buttonTitleStyle, { alignSelf: 'center' }]) }, props.buttonTitle));
        }
        return null;
    };
    if (props.containerStyle) {
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
            React.createElement(Button, Object.assign({}, buttonProps, { onClick: handleonPress }),
                React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.buttonContainer]) },
                    renderIcon(props.leftIcon),
                    renderTitle(),
                    renderIcon(props.rightIcon)))));
    }
    return (React.createElement(Button, Object.assign({}, buttonProps, { onClick: handleonPress }),
        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.buttonContainer]) },
            renderIcon(props.leftIcon),
            renderTitle(),
            renderIcon(props.rightIcon))));
};
const delete_buttontoolbar_keys = ['containerStyle', 'children'];
const ButtonToolbarV = (props) => {
    const buttontoolbarProps = props ? Object.assign({}, props) : {};
    deleteKeys(buttontoolbarProps, delete_buttontoolbar_keys);
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
        React.createElement(ButtonToolbar, { style: reactNative.StyleSheet.flatten([styles.buttontoolbarStyle]) },
            React.createElement(React.Fragment, null, props.children))));
};
const delete_buttongroup_keys = ['containerStyle', 'children'];
const ButtonGroupV = (props) => {
    const buttonGroupProps = props ? Object.assign({}, props) : {};
    deleteKeys(buttonGroupProps, delete_buttongroup_keys);
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
        React.createElement(ButtonGroup, Object.assign({}, buttonGroupProps),
            React.createElement(React.Fragment, null, props.children))));
};
const delete_iconbutton_keys = ['containerStyle', 'icon', 'iconStyle'];
const IconButtonV = (props) => {
    const iconButtonVProps = props ? Object.assign({}, props) : {};
    deleteKeys(iconButtonVProps, delete_iconbutton_keys);
    const renderIcon = (icon) => {
        const _comp = icon ? Object.assign({}, icon) : {};
        const _iconProps = renameKeys(_comp, { name: 'icon' });
        return (React.createElement(Icon, Object.assign({}, _iconProps)));
    };
    if (props.containerStyle) {
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
            React.createElement(IconButton, Object.assign({}, iconButtonVProps, { style: props.iconStyle, icon: renderIcon(props.icon) }))));
    }
    return (React.createElement(IconButton, Object.assign({}, iconButtonVProps, { icon: renderIcon(props.icon), style: props.iconStyle })));
};

exports.ButtonGroupV = ButtonGroupV;
exports.ButtonToolbarV = ButtonToolbarV;
exports.ButtonV = ButtonV;
exports.IconButtonV = IconButtonV;
