"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Table = _interopRequireDefault(require("./Table"));

var _default = _Table.default;
exports.default = _default;
module.exports = exports.default;