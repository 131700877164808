"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Tooltip = _interopRequireDefault(require("./Tooltip"));

var _default = _Tooltip.default;
exports.default = _default;
module.exports = exports.default;