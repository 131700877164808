import React from 'react';
import {
  ActivityIndicator,
  ViewStyle,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
} from 'react-native';
import {Image} from 'react-native-elements';

interface AppProps {
  containerStyle?: ViewStyle;
  placeholderStyle?: ViewStyle;
  PlaceholderContent?: any;
  ImageComponent?: any;
  source: ImageSourcePropType;
  imageStyle: ImageStyle;
}

export const ImageV = (props: AppProps): JSX.Element => {
  const {
    containerStyle,
    placeholderStyle,
    PlaceholderContent,
    ImageComponent,
    source,
    imageStyle,
  } = props;
  return (
    <Image
      source={source && source}
      containerStyle={StyleSheet.flatten([containerStyle && containerStyle])}
      style={StyleSheet.flatten([imageStyle && imageStyle])}
      PlaceholderContent={
        PlaceholderContent ? PlaceholderContent : <ActivityIndicator />
      }
      placeholderStyle={StyleSheet.flatten([
        placeholderStyle && placeholderStyle,
      ])}
      ImageComponent={ImageComponent && ImageComponent}
    />
  );
};
