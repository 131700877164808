"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Divider = _interopRequireDefault(require("./Divider"));

var _default = _Divider.default;
exports.default = _default;
module.exports = exports.default;