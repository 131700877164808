'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var Navbar = _interopDefault(require('rsuite/lib/Navbar'));

const ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = [
    'navbarStyle',
    'renderNavbarHeader',
    'renderNavBody'
];
const NavbarV = (props) => {
    const _props = props ? Object.assign({}, props) : {};
    deleteKeys(_props, delete_keys);
    const renderHeader = () => {
        const _header = [];
        if (props.renderNavbarHeader) {
            _header.push(React.createElement(Navbar.Header, { key: ids.generate() }, props.renderNavbarHeader()));
        }
        return _header;
    };
    const renderBody = () => {
        const _body = [];
        if (props.renderNavBody) {
            _body.push(React.createElement(Navbar.Body, { key: ids.generate() }, props.renderNavBody()));
        }
        return _body;
    };
    return (React.createElement(Navbar, Object.assign({}, _props, { style: reactNative.StyleSheet.flatten([props.navbarStyle]) }),
        renderHeader(),
        renderBody()));
};

exports.NavbarV = NavbarV;
