module.exports = require('./dist');

if (require.main === module) {
  const {
    LoggerApplication
  } = require('./dist/application');
  const app = new LoggerApplication();
  app.main().catch(err => {
    console.error(err);
    process.exit(1);
  });
}
