"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatter = exports.FormatTypes = void 0;
var toLocalTime_1 = require("./toLocalTime");
var FormatTypes;
(function (FormatTypes) {
    FormatTypes["MONEY"] = "money";
    FormatTypes["PERCENTAGE"] = "percentage";
    FormatTypes["DECIMAL_PERCENTAGE"] = "decimalPercentage";
    FormatTypes["DATE"] = "date";
    FormatTypes["DATE_LOCAL"] = "dateLocal";
    FormatTypes["DECIMAL"] = "decimal";
    FormatTypes["DAYS"] = "days";
    FormatTypes["MONTHS"] = "months";
})(FormatTypes = exports.FormatTypes || (exports.FormatTypes = {}));
exports.formatter = function (data, format) {
    if (!data && format === FormatTypes.MONEY) {
        return '$0.00';
    }
    if (data == null) {
        return 'N/A';
    }
    var stringData = data.toString();
    switch (format) {
        case FormatTypes.MONEY:
            var parsedMoney = parseFloat(stringData);
            if (!parsedMoney) {
                return 'N/A';
            }
            return "$" + parsedMoney.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        case FormatTypes.PERCENTAGE:
            return Math.round(parseFloat(stringData)) + "%";
        case FormatTypes.DECIMAL_PERCENTAGE:
            return parseInt(stringData).toFixed(2) + "%";
        case FormatTypes.DATE:
            return toLocalTime_1.toLocalTime(stringData).toLocaleDateString('en-us');
        case FormatTypes.DATE_LOCAL:
            return new Date(stringData).toLocaleDateString('en-us');
        case FormatTypes.DECIMAL:
            var parsedDecimal = parseFloat(stringData);
            if (!parsedDecimal) {
                return 'N/A';
            }
            return parsedDecimal.toFixed(2);
        case FormatTypes.DAYS:
            return stringData + " days";
        case FormatTypes.MONTHS:
            return stringData + " months";
    }
};
