'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactFormRs = require('react-form-rs');
var reactLogoSpinnerExt = require('react-logo-spinner-ext');
var reactNative = require('react-native');
var expoLinearGradient = require('expo-linear-gradient');
var reactNativeElements = require('react-native-elements');
var reactViewportProvider = require('react-viewport-provider');
var Container = _interopDefault(require('rsuite/lib/Container'));
var Content = _interopDefault(require('rsuite/lib/Content'));
var Divider = _interopDefault(require('rsuite/lib/Divider'));

var ButtonType;
(function (ButtonType) {
    ButtonType["Solid"] = "solid";
    ButtonType["Clear"] = "clear";
    ButtonType["Outline"] = "outline";
})(ButtonType || (ButtonType = {}));
const ButtonV = (props) => {
    const { buttonStyle, containerStyle, disabled, disabledStyle, disabledTitleStyle, icon, iconContainerStyle, iconRight, linearGradientProps, loading, loadingProps, loadingStyle, onPress, raised, title, titleProps, titleStyle, type, TouchableComponent, ViewComponent, } = props;
    return (React.createElement(reactNativeElements.Button, { buttonStyle: buttonStyle && buttonStyle, containerStyle: containerStyle && containerStyle, disabled: disabled, disabledStyle: disabledStyle && disabledStyle, disabledTitleStyle: disabledTitleStyle && disabledTitleStyle, icon: icon && icon, iconContainerStyle: iconContainerStyle && iconContainerStyle, iconRight: iconRight && iconRight, linearGradientProps: linearGradientProps && linearGradientProps, loading: loading, loadingProps: loadingProps && loadingProps, loadingStyle: loadingStyle && loadingStyle, onPress: onPress && onPress, raised: raised && raised, title: title && title, titleProps: titleProps && titleProps, titleStyle: titleStyle && titleStyle, type: type && type, TouchableComponent: TouchableComponent, ViewComponent: linearGradientProps
            ? expoLinearGradient.LinearGradient
            : ViewComponent
                ? ViewComponent
                : reactNative.View }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
const ids = require('short-id');
const AVATAR_WIDTH = 40;
const AVATAR_HEIGHT = 40;
const PROFILE_ICON_COLOR = '#333333';
const TITLE_FONT_COLOR = '#565D61';
const TEXT_COLOR = '#414B47';
const styles$1 = {
    avatarContainerStyle: {
        width: AVATAR_WIDTH,
        height: AVATAR_HEIGHT,
    },
    titleContainerStyle: {
        height: 35,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    titleStyle: {
        color: 'rgba(86,93,97,0.8)',
        fontWeight: 600,
        marginLeft: 10,
    },
    subtitleStyle: {
        color: TITLE_FONT_COLOR,
        marginLeft: 5,
    },
    avatarTitleContainerStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 15,
    },
    loginButtonStyle: {
        width: 65,
        borderRadius: 5,
        alignSelf: 'center',
        borderColor: TEXT_COLOR,
    },
    signupButtonStyle: {
        width: 80,
        borderRadius: 5,
        marginLeft: 15,
        alignSelf: 'center',
    },
    signupButtonContainer: {
        width: 65,
        borderRadius: 5,
        alignSelf: 'center',
    },
    loginButtonContainerStyle: {
        alignItems: 'center',
    },
    signupButtonContainerStyle: {
        alignItems: 'center',
    },
    loginButtonTitleStyle: {
        color: TEXT_COLOR,
        fontSize: 15,
    },
    signupButtonTitleStyle: {
        color: '#FFFFFF',
        fontSize: 15,
    },
    profileContainer: {
        width: '80%',
    },
    profileItemContainer: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 30,
        marginBottom: 25,
    },
    profileIconStyle: {
        color: PROFILE_ICON_COLOR,
        alignSelf: 'center',
        width: 10,
    },
    profileTitleGroupContainer: {
        marginLeft: 30,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        width: '100%',
    },
    container: {}
};
const HeaderV = (props) => {
    const { width, bp } = reactViewportProvider.useViewport();
    const renderBody = () => {
        const _body = [];
        if (props.title) {
            _body.push(renderHeader());
            _body.push(React.createElement(Divider, { key: ids.generate() }));
            //    if(props.renderBody) {
            //        _body.push(props.renderBody())
            //    }
        }
        return _body;
    };
    const renderHeader = () => {
        if (props.title) {
            return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'center', style: reactNative.StyleSheet.flatten([styles$1.titleContainerStyle]) },
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: bp !== 'sm' && bp !== 'xs' ? 2 : 8 },
                    React.createElement(reactNative.TouchableOpacity, { style: reactNative.StyleSheet.flatten([styles$1.avatarTitleContainerStyle]), onPress: props.onHeaderLogoPress },
                        props.leftAvatar && renderAvatar(props.leftAvatar),
                        props.title && React.createElement(reactNative.View, null, renderTitle(props.title))))));
        }
        return null;
    };
    const renderAvatar = (avatar) => {
        if (avatar) {
            return (React.createElement(AvatarV, { containerStyle: reactNative.StyleSheet.flatten([styles$1.avatarContainerStyle]), size: AVATAR_WIDTH, rounded: true, source: { uri: avatar } }));
        }
        return null;
    };
    const renderTitle = (title) => {
        const _title = [];
        if (title) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'title2', style: reactNative.StyleSheet.flatten([
                    styles$1.titleStyle,
                    props.titleStyle,
                    props.titleColor && { color: props.titleColor },
                ]) }, String(title)));
        }
        if (props.subtitle) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'callout', style: reactNative.StyleSheet.flatten([
                    styles$1.subtitleStyle,
                    props.subtitleStyle,
                    props.subtitleColor && { color: props.subtitleColor },
                ]) }, String(props.subtitle).toLowerCase()));
        }
        return _title;
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles$1.container, { width: 1 * width }]) }, renderBody()));
};

//@ts-nocheck
const BACKGROUND_COLOR = '#FAFBFC';
const WAVE_COLOR = '#f05776';
const SUBTITLE_COLOR = '#636669';
const GETTING_STARTED_BUTTON_TITLE = 'Signup';
const SignupPageInternal = (props) => {
    var _a;
    const { width, height, bp } = reactViewportProvider.useViewport();
    const styles = {
        container: {
            flex: 1,
            width: 1 * width,
            height: 1 * height,
        },
        waveContainer: {
            background: BACKGROUND_COLOR,
            textAlign: 'center',
            overflow: 'hidden',
            width: 1 * width,
            bottom: 0,
            position: 'fixed',
        },
        bodyContainer: {
            width: 1 * width,
            //height: 0.8 * height,
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 20,
        },
        bodyTitleStyle: {
            color: 'rgba(99,102,105,1)',
            lineHeight: 20,
            alignSelf: 'center',
            textAlign: 'center',
            letterSpacing: 2,
            marginTop: 2,
        },
        formContainerStyle: {
            width: bp === 'xs' || bp === 'sm' ? 0.7 * width : 0.3 * width,
            height: 130,
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
        },
        buttonFormGroupContainer: {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        gettingStartedButtonStyle: {
            width: bp === 'xs' || bp === 'sm' ? 0.7 * width : 0.3 * width,
            height: 42,
            borderRadius: 5,
            alignSelf: 'center',
        },
        gettingStartedContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        getStartedTitleStyle: {
            fontSize: 18,
        },
        loginGroupContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: width,
            marginTop: 50,
        },
        qrContainerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            width: props.qrCodeSize ? props.qrCodeSize + 30 : 150,
            height: props.qrCodeSize ? props.qrCodeSize + 30 : 150,
            backgroundColor: '#fff',
            border: `10px solid ${SUBTITLE_COLOR}`,
        },
        qrCodeDescriptionStyle: {
            textAlign: 'center',
            marginTop: 20,
        },
        qrDescContainerStyle: {
            width: props.qrCodeSize ? props.qrCodeSize + 30 : 150,
            alignItems: 'center',
            justifyContent: 'center',
        },
        dividerStyle: {
            height: 0.3 * height,
            width: 2,
        },
        forgetPasswordContainerStyle: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        forgetPasswordTextStyle: {
            textAlign: 'flex-end',
        },
    };
    const renderContent = () => {
        return (React.createElement(Content, null,
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.bodyContainer]) },
                renderTitle(),
                renderSignupContent())));
    };
    const renderSignupContent = () => {
        if (props.loading) {
            if (props.renderLoadingContent) {
                return props.renderLoadingContent();
            }
            return React.createElement(reactLogoSpinnerExt.LogoSpinnerV, Object.assign({}, props));
        }
        return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'space-around', style: reactNative.StyleSheet.flatten([styles.loginGroupContainer]), align: 'top' }, bp !== 'xs' && bp !== 'sm' && props.qrValue ? (React.createElement(React.Fragment, null,
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 20 }, renderForm()))) : (React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 20 }, renderForm()))));
    };
    const renderTitle = () => {
        if (props.bodyTitle) {
            return (React.createElement(TextV, { type: 'title3', style: reactNative.StyleSheet.flatten([
                    styles.bodyTitleStyle,
                    props.bodyTitleStyle,
                ]) }, props.bodyTitle));
        }
        return null;
    };
    const renderForm = () => {
        var _a;
        if (props.formFields) {
            return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.buttonFormGroupContainer]) },
                React.createElement(reactFormRs.FormV, { components: props.formFields, formContext: props.formContext, containerStyle: reactNative.StyleSheet.flatten([
                        styles.formContainerStyle,
                        props.formContainerStyle,
                    ]), validateForm: props.validateForm, onValidateResult: props.onValidateResult, defaultValues: (_a = props.defaultValues) !== null && _a !== void 0 ? _a : [] }),
                React.createElement(ButtonV, { type: ButtonType.Solid, containerStyle: reactNative.StyleSheet.flatten([
                        styles.gettingStartedContainer,
                    ]), buttonStyle: reactNative.StyleSheet.flatten([
                        styles.gettingStartedButtonStyle,
                        props.signupButtonColor && {
                            backgroundColor: props.signupButtonColor,
                            borderColor: props.signupButtonColor,
                        },
                    ]), title: GETTING_STARTED_BUTTON_TITLE, titleStyle: reactNative.StyleSheet.flatten([styles.getStartedTitleStyle]), onPress: props.onGetStarted })));
        }
        return null;
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.container, props.containerStyle]) },
        React.createElement(Container, null,
            React.createElement(HeaderV, Object.assign({}, props)),
            React.createElement(Container, null, renderContent()),
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.waveContainer]) },
                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1440 250" },
                    React.createElement("path", { fill: (_a = props.waveColor) !== null && _a !== void 0 ? _a : WAVE_COLOR, "fill-opacity": "1", d: "M0,160L34.3,181.3C68.6,203,137,245,206,224C274.3,203,343,117,411,80C480,43,549,53,617,96C685.7,139,754,213,823,229.3C891.4,245,960,203,1029,165.3C1097.1,128,1166,96,1234,106.7C1302.9,117,1371,171,1406,197.3L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z" }))))));
};
const SignupPageV = (props) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(SignupPageInternal, Object.assign({}, props))));
};

exports.SignupPageV = SignupPageV;
