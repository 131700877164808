'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var Chip = _interopDefault(require('@material-ui/core/Chip'));
var styles = require('@material-ui/core/styles');
var React = _interopDefault(require('react'));

const useStyles = styles.makeStyles({
    chip: (style) => (Object.assign({}, style)),
});
const ChipV = (props) => {
    const classes = useStyles(props.chipStyle);
    return React.createElement(Chip, Object.assign({}, props, { className: classes.chip }));
};

exports.ChipV = ChipV;
