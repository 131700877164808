"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.install = void 0;
var UnoJs = __importStar(require("./"));
exports.install = function () {
    if (!Array.prototype.hasOwnProperty('toDate')) {
        Object.defineProperty(Array.prototype, 'toDate', {
            value: function () {
                return UnoJs.toDate(this);
            },
        });
    }
    if (!String.prototype.hasOwnProperty('humanize')) {
        Object.defineProperty(String.prototype, 'humanize', {
            value: function () {
                return UnoJs.humanize(this);
            },
        });
    }
    if (!Date.prototype.hasOwnProperty('toLocalTime')) {
        Object.defineProperty(Date.prototype, 'toLocalTime', {
            value: function () {
                return UnoJs.toLocalTime(this);
            },
        });
    }
    if (!String.prototype.hasOwnProperty('toLocalTime')) {
        Object.defineProperty(String.prototype, 'toLocalTime', {
            value: function () {
                return UnoJs.toLocalTime(this);
            },
        });
    }
    if (!String.prototype.hasOwnProperty('toTitleCase')) {
        Object.defineProperty(String.prototype, 'toTitleCase', {
            value: function () {
                return UnoJs.toTitleCase(this);
            },
        });
    }
    if (!String.prototype.hasOwnProperty('truncateText')) {
        Object.defineProperty(String.prototype, 'truncateText', {
            value: function (complement, length) {
                return UnoJs.truncateText(complement, this, length);
            },
        });
    }
    if (!Object.prototype.hasOwnProperty('objectDifference')) {
        Object.defineProperty(Object.prototype, 'objectDifference', {
            value: function (toCompare) {
                return UnoJs.objectDifference(this, toCompare);
            },
        });
    }
    if (!Object.prototype.hasOwnProperty('validateNotNull')) {
        Object.defineProperty(Object.prototype, 'validateNotNull', {
            value: function () {
                return UnoJs.validateNotNull(this);
            },
        });
    }
    if (!Array.prototype.hasOwnProperty('removeDuplicated')) {
        Object.defineProperty(Array.prototype, 'removeDuplicated', {
            value: function (prop) {
                return UnoJs.removeDuplicated(this, prop);
            },
        });
    }
};
