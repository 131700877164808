"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLocaleString = void 0;
var toLocalTime_1 = require("./toLocalTime");
var dateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
};
exports.toLocaleString = function (date, locales) {
    if (locales === void 0) { locales = 'en-us'; }
    var dateString = toLocalTime_1.toLocalTime(date).toLocaleDateString(locales, dateTimeFormatOptions);
    return dateString !== 'Invalid Date' ? dateString : '';
};
