"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDate = void 0;
var toLocalTime_1 = require("./toLocalTime");
var regex = /^(19[8-9]\d|20\d{2})[-](0[1-9]|1[0-2])[-](0[1-9]|[12]\d|3[01])[T](0\d|1\d|2[0-4])[:]([0-5]\d)[:]([0-5]\d)[.](\d{3})[Z]$/;
exports.toDate = function (obj) {
    Object.keys(obj).map(function (prop) {
        if (typeof obj[prop] === 'string' &&
            obj[prop].length > 5 &&
            !isNaN(new Date(obj[prop]).getDate()) &&
            new Date(obj[prop]).toISOString().match(regex)) {
            obj[prop] = toLocalTime_1.toLocalTime(obj[prop]);
        }
        if (typeof obj[prop] === 'object') {
            if (obj[prop] instanceof Array) {
                obj[prop].map(exports.toDate);
            }
            else if (obj[prop] == null || obj[prop] == undefined) {
                // TODO: Marco why is this return void, but you return a value?
                return obj[prop];
            }
            else {
                Object.keys(obj[prop]).map(function () { return exports.toDate(obj[prop]); });
            }
        }
    });
};
