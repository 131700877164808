"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _InputNumber = _interopRequireDefault(require("./InputNumber"));

var _default = _InputNumber.default;
exports.default = _default;
module.exports = exports.default;