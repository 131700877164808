"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _FlexboxGrid = _interopRequireDefault(require("./FlexboxGrid"));

var _default = _FlexboxGrid.default;
exports.default = _default;
module.exports = exports.default;