"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Maybe = void 0;
var Maybe = /** @class */ (function () {
    function Maybe(value) {
        this.value = value;
    }
    Maybe.prototype.map = function (fn) {
        return new Maybe(this.value !== null && this.value !== undefined ? fn(this.value) : null);
    };
    Maybe.prototype.getOrDefault = function (defaultValue) {
        return this.value !== null ? this.value : defaultValue;
    };
    return Maybe;
}());
exports.Maybe = Maybe;
