/* eslint lines-around-comment: [2, {beforeBlockComment: false}] */
"use strict";

var yaml = require("js-yaml"),
    ono = require("ono");

/**
 * Simple YAML parsing functions, similar to {@link JSON.parse} and {@link JSON.stringify}
 */
module.exports = {
  /**
   * Parses a YAML string and returns the value.
   *
   * @param {string} text - The YAML string to be parsed
   * @param {function} [reviver] - Not currently supported. Provided for consistency with {@link JSON.parse}
   * @returns {*}
   */
  parse: function yamlParse (text, reviver) {
    try {
      return yaml.safeLoad(text);
    }
    catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      else {
        // https://github.com/nodeca/js-yaml/issues/153
        throw ono(e, e.message);
      }
    }
  },

  /**
   * Converts a JavaScript value to a YAML string.
   *
   * @param   {*} value - The value to convert to YAML
   * @param   {function|array} replacer - Not currently supported. Provided for consistency with {@link JSON.stringify}
   * @param   {string|number} space - The number of spaces to use for indentation, or a string containing the number of spaces.
   * @returns {string}
   */
  stringify: function yamlStringify (value, replacer, space) {
    try {
      var indent = (typeof space === "string" ? space.length : space) || 2;
      return yaml.safeDump(value, { indent: indent });
    }
    catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      else {
        // https://github.com/nodeca/js-yaml/issues/153
        throw ono(e, e.message);
      }
    }
  }
};
