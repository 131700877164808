'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
var reactPanelRs = require('react-panel-rs');
var Divider = _interopDefault(require('rsuite/lib/Divider'));
var Tooltip = _interopDefault(require('rsuite/lib/Tooltip'));
var Whisper = _interopDefault(require('rsuite/lib/Whisper'));

const ImageV = (props) => {
    const { containerStyle, placeholderStyle, PlaceholderContent, ImageComponent, source, imageStyle, } = props;
    return (React.createElement(reactNativeElements.Image, { source: source && source, containerStyle: reactNative.StyleSheet.flatten([containerStyle && containerStyle]), style: reactNative.StyleSheet.flatten([imageStyle && imageStyle]), PlaceholderContent: PlaceholderContent ? PlaceholderContent : React.createElement(reactNative.ActivityIndicator, null), placeholderStyle: reactNative.StyleSheet.flatten([
            placeholderStyle && placeholderStyle,
        ]), ImageComponent: ImageComponent && ImageComponent }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const ids = require('short-id');
const AVATAR_WIDTH = 80;
const AVATAR_HEIGHT = 80;
const TITLE_FONT_COLOR = '#333333';
const TEMPLATE_ICON_COLOR = '#333333';
const styles$1 = {
    avatarContainerStyle: {
        width: AVATAR_WIDTH,
        height: AVATAR_HEIGHT,
    },
    titleContainerStyle: {
        height: 100,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    titleStyle: {
        color: TITLE_FONT_COLOR,
    },
    subtitleStyle: {
        color: TITLE_FONT_COLOR,
        marginLeft: 5,
    },
    avatarTitleContainerStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 15,
    },
    headerButtonStyle: {
        width: 120,
        borderRadius: 0,
        alignSelf: 'center',
    },
    headerButtonContainerStyle: {
        alignItems: 'center',
    },
    headerButtonTitleStyle: {
        fontWeight: 500,
    },
    templateItemContainer: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 30,
        marginBottom: 25,
        minHeight: 80,
        border: '0.5px solid #ebebeb',
        borderRadius: 5,
    },
    templateIconStyle: {
        color: TEMPLATE_ICON_COLOR,
        alignSelf: 'center',
        width: 10,
    },
    titleStyle: {
        fontSize: 24,
        padding: 20,
        lineHeight: 42
    },
    iconStyle: {
        display: 'flex',
        padding: 10,
        alignItems: 'center',
    },
    templateTitleGroupContainer: {
        marginLeft: 5,
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: '100%',
        flex: 1,
        flexWrap: 'wrap',
    },
};
const CredentialTemplateV = (props) => {
    const onCardClick = eventKey => {
        props.onCardClick && props.onCardClick(eventKey);
    };
    const renderBody = () => {
        if (props.templateDetails && Array.isArray(props.templateDetails)) {
            const _templateDetails = props.templateDetails;
            return (React.createElement(reactFlexboxGridRs.FBGridV, { style: reactNative.StyleSheet.flatten([styles$1.templateContainer]), justify: 'start' },
                props.title ?
                    (React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 24 },
                        React.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([styles$1.titleStyle, props.titleStyle]) }, props.title))) : null,
                renderTemplateItems(_templateDetails)));
        }
        return null;
    };
    const renderTemplateItems = (template) => {
        const _template = [];
        if (template && Array.isArray(template)) {
            template.forEach((prof, item) => {
                var _a, _b, _c, _d, _e;
                _template.push(React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 8 },
                    React.createElement("div", { onClick: () => onCardClick(prof.key), style: reactNative.StyleSheet.flatten([{ cursor: 'pointer' }]) },
                        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles$1.templateItemContainer]) },
                            prof.icon && (React.createElement(Whisper, { placement: "top", trigger: "hover", speaker: React.createElement(Tooltip, null, (_a = prof.tooltip) !== null && _a !== void 0 ? _a : prof.title) },
                                React.createElement("div", { style: reactNative.StyleSheet.flatten([styles$1.iconStyle]) },
                                    React.createElement(ImageV, { source: { uri: (_b = prof.icon) === null || _b === void 0 ? void 0 : _b.imageURI }, imageStyle: reactNative.StyleSheet.flatten([
                                            styles$1.templateIconStyle,
                                            ((_c = prof.icon) === null || _c === void 0 ? void 0 : _c.imgWidth) && {
                                                width: prof.icon.imgWidth,
                                            },
                                            ((_d = prof.icon) === null || _d === void 0 ? void 0 : _d.imgHeight) && {
                                                height: (_e = prof.icon) === null || _e === void 0 ? void 0 : _e.imgHeight,
                                            },
                                            props.iconStyle
                                        ]) }),
                                    React.createElement(Divider, { vertical: true, style: reactNative.StyleSheet.flatten([
                                            {
                                                height: prof.icon.imgHeight
                                                    ? prof.icon.imgHeight - 15
                                                    : 50,
                                            },
                                        ]) })))),
                            prof.title && (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                                    styles$1.templateTitleGroupContainer,
                                ]) },
                                React.createElement(TextV, { type: 'subhead' }, prof.type === 'url' ? (React.createElement("a", { href: prof.title, target: "_blank", style: reactNative.StyleSheet.flatten([
                                        { color: TITLE_FONT_COLOR },
                                    ]) }, prof.title)) : (prof.title)),
                                prof.subtitle1 && (React.createElement(TextV, { type: 'footnote', numberOfLines: 2 }, prof.subtitle1)),
                                prof.subtitle2 && (React.createElement(TextV, { type: 'footnote', numberOfLines: 2 }, prof.subtitle2))))))));
            });
        }
        return _template;
    };
    return (React.createElement(reactPanelRs.PanelV, { shaded: props.shaded, bordered: props.bordered, bodyFill: true, style: reactNative.StyleSheet.flatten([props.containerStyle]) },
        renderBody(),
        props.children));
};

exports.CredentialTemplateV = CredentialTemplateV;
