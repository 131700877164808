'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var Avatar = _interopDefault(require('rsuite/lib/Avatar'));

function renameKeys(obj, newKeys, deleteKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    if (deleteKeys && Array.isArray(deleteKeys)) {
        deleteKeys.forEach((key) => {
            delete ret[key];
        });
    }
    return ret;
}
const mapper = { avatarStyle: 'style' };
const deleteKeys = ['containerStyle'];
const AvatarV = (props) => {
    const _props = renameKeys(props, mapper, deleteKeys);
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
        React.createElement(Avatar, Object.assign({}, _props), props.children)));
};

exports.AvatarV = AvatarV;
