'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var Drawer = _interopDefault(require('rsuite/lib/Drawer'));

const ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { 'drawerStyle': 'style' };
const delete_keys = ['renderHeader', 'renderBody', 'renderFooter', 'drawerTitle'];
const DrawerV = (props) => {
    let _props = props ? Object.assign({}, props) : {};
    _props = renameKeys(_props, mapper);
    deleteKeys(_props, delete_keys);
    const renderHeader = () => {
        let _comp = [];
        if (props.renderHeader) {
            _comp.push(React.createElement(Drawer.Header, { key: ids.generate() },
                props.drawerTitle && (React.createElement(Drawer.Title, null, props.drawerTitle)),
                props.renderHeader()));
        }
        return _comp;
    };
    const renderBody = () => {
        let _comp = [];
        if (props.renderBody) {
            _comp.push(React.createElement(Drawer.Body, { key: ids.generate() }, props.renderBody()));
        }
        return _comp;
    };
    const renderFooter = () => {
        let _comp = [];
        if (props.renderFooter) {
            _comp.push(React.createElement(Drawer.Footer, { key: ids.generate() }, props.renderFooter()));
        }
        return _comp;
    };
    const handleHide = () => {
        props.onHide && props.onHide();
    };
    return (React.createElement(Drawer, Object.assign({}, _props, { onHide: handleHide }),
        renderHeader(),
        renderBody(),
        renderFooter()));
};

exports.DrawerV = DrawerV;
