'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactChartjs2 = require('react-chartjs-2');

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = ['containerStyle'];
const LineChartV = (props) => {
    let _props = Object.assign({}, props);
    deleteKeys(_props, delete_keys);
    return (
    // <View style={StyleSheet.flatten([props.containerStyle])}>
    React.createElement(reactChartjs2.Line, Object.assign({}, _props))
    // </View>
    );
};

exports.LineChartV = LineChartV;
