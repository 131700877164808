'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactImageBackground = require('react-image-background');
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
require('expo-linear-gradient');
require('react-native-dash');
var reactTimelineRs = require('react-timeline-rs');
var reactViewportProvider = require('react-viewport-provider');
var Icon = _interopDefault(require('rsuite/lib/Icon'));
require('rsuite/lib/IconButton');
var Tooltip = _interopDefault(require('rsuite/lib/Tooltip'));
var Whisper = _interopDefault(require('rsuite/lib/Whisper'));

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

var ButtonType;
(function (ButtonType) {
    ButtonType["Solid"] = "solid";
    ButtonType["Clear"] = "clear";
    ButtonType["Outline"] = "outline";
})(ButtonType || (ButtonType = {}));

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const ids = require('short-id');
const PROFILE_BACKGROUND_COLOR = '#20334C';
const TITLE_COLOR = '#B6B7B8';
const AVATAR_BG_COLOR = '#F5F5F5';
const BACKGROUND_IMAGE = 'https://i.ibb.co/mFkJgxw/pattern-2.png';
const SUBTITLE_COLOR = '#989CA0';
const BUTTON_TITLE_COLOR = '#F5F5F5';
const PROFILE_ICON_COLOR = '#333333';
const getTitle = (name) => {
    var _a;
    const str = '';
    if (name) {
        const _name = name.split(' ');
        const _res = [];
        for (let i = 0; i < 2; ++i) {
            _res[i] = (_a = _name[i][0]) === null || _a === void 0 ? void 0 : _a.toUpperCase();
        }
        return _res.join(' ').replace(/\s+/g, '');
    }
    return str;
};
const ProfileTemplateInternal = (props) => {
    var _a, _b, _c, _d, _e;
    const { height } = reactViewportProvider.useViewport();
    const styles = reactNative.StyleSheet.create({
        containerStyle: {
            justifyContent: 'flex-start',
            overflow: 'auto',
            height: '100vh',
        },
        imageBackgroundStyle: {
            backgroundColor: (_a = props.profileBackgroundColor) !== null && _a !== void 0 ? _a : PROFILE_BACKGROUND_COLOR,
        },
        closeIconStyle: {
            color:  TITLE_COLOR ,
            backgroundColor: (_b = props.profileBackgroundColor) !== null && _b !== void 0 ? _b : PROFILE_BACKGROUND_COLOR,
            borderWidth: 3,
            borderColor: (_c = props.fontColor) !== null && _c !== void 0 ? _c : TITLE_COLOR,
        },
        closeIconContainerStyle: {
            width: '100%',
        },
        closeIconItemContainerStyle: {
            alignItems: 'flex-end',
            marginRight: 15,
        },
        avatarOverlayContainerStyle: {
            backgroundColor: AVATAR_BG_COLOR,
        },
        avatarTitleStyle: {
            color: (_d = props.profileBackgroundColor) !== null && _d !== void 0 ? _d : PROFILE_BACKGROUND_COLOR,
        },
        shortprofileAvatarContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
            paddingBottom: 10,
        },
        nickNameTitleStyle: {
            color: AVATAR_BG_COLOR,
            marginTop: 15,
        },
        emailTitleStyle: {
            color: SUBTITLE_COLOR,
            marginTop: 15,
        },
        vidTitleStyle: {
            color: SUBTITLE_COLOR,
            marginTop: 5,
        },
        vidGroupContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        vIDTooltipIconStyle: {
            color: SUBTITLE_COLOR,
        },
        vIDTooltipIconContainer: {
            marginLeft: 10,
            alignItems: 'center',
            justifyContent: 'center',
        },
        profileFooterContainer: {
            top: 25,
        },
        footerContainer: {
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row',
            marginTop: 10,
        },
        buttonTitleStyle: {
            color: (_e = props.buttonTitleColor) !== null && _e !== void 0 ? _e : BUTTON_TITLE_COLOR,
            fontSize: 16,
            fontWeight: 'bold',
        },
        profileContainer: {
            width: '100%',
            marginTop: 30,
            backgroundColor: '#F4F4F4',
        },
        profileItemContainer: {
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 30,
            marginBottom: 20,
            marginTop: 20,
        },
        profileIconStyle: {
            color: PROFILE_ICON_COLOR,
            alignSelf: 'center',
            width: 10,
        },
        profileTitleGroupContainer: {
            marginLeft: 30,
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        timelineContainerStyle: {
            backgroundColor: '#ffffff',
            width: '100%',
            // minHeight: 600,
            // minHeight: height - 280,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            paddingTop: 20,
            paddingBottom: 20,
        },
        timeLineOuterContainerStyle: {},
        labelStyle: {
            display: 'block',
            textAlign: 'center',
            fontSize: 18,
            lineHeight: 20,
        },
        valueStyle: {
            display: 'block',
            textAlign: 'center',
            fontSize: 24,
            lineHeight: 26
        }
    });
    const _getProfileDetails = () => {
        const _profDetail = [];
        props === null || props === void 0 ? void 0 : props.profileDetails.map((key, index) => {
            _profDetail.push(React.createElement("div", { style: { padding: '5px 15px' } },
                React.createElement(TextV, { style: reactNative.StyleSheet.flatten([styles.valueStyle, key === null || key === void 0 ? void 0 : key.valueStyle]) }, key === null || key === void 0 ? void 0 : key.value),
                React.createElement(TextV, { style: reactNative.StyleSheet.flatten([styles.labelStyle, key === null || key === void 0 ? void 0 : key.labelStyle]) }, key === null || key === void 0 ? void 0 : key.label)));
        });
        return _profDetail;
    };
    const renderShortProfile = () => {
        const _prof = [];
        if (props.fullName) {
            _prof.push(React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten(styles.shortprofileAvatarContainer) },
                React.createElement(AvatarV, { key: ids.generate(), source: props.profileImage ? { uri: props.profileImage } : undefined, title: getTitle(props.fullName), overlayContainerStyle: reactNative.StyleSheet.flatten([
                        styles.avatarOverlayContainerStyle,
                    ]), size: AvatarSize.Medium, rounded: true, titleStyle: reactNative.StyleSheet.flatten([styles.avatarTitleStyle]) }),
                props.nickName && (React.createElement(TextV, { type: 'title3', style: reactNative.StyleSheet.flatten([styles.nickNameTitleStyle]) }, props.nickName)),
                props.email && (React.createElement(TextV, { type: 'callout', style: reactNative.StyleSheet.flatten([styles.emailTitleStyle]) }, props.email)),
                props.vid && (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.vidGroupContainer]) },
                    React.createElement(TextV, { type: 'callout', style: reactNative.StyleSheet.flatten([styles.vidTitleStyle]) },
                        "vID: ",
                        props.vid),
                    React.createElement(Whisper, { placement: "bottomEnd", trigger: "hover", speaker: React.createElement(Tooltip, null, props.vidTooltip) },
                        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.vIDTooltipIconContainer]) },
                            React.createElement(Icon, { icon: 'help-o', style: reactNative.StyleSheet.flatten(styles.vIDTooltipIconStyle) }))))),
                (props === null || props === void 0 ? void 0 : props.profileDetails) &&
                    (React.createElement(React.Fragment, null,
                        React.createElement(reactNative.View, { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' } }, _getProfileDetails())))));
        }
        return _prof;
    };
    const renderTimeline = () => {
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                styles.timeLineOuterContainerStyle,
                props.timeLineOuterContainerStyle,
            ]) },
            React.createElement(reactTimelineRs.TimeLineV, Object.assign({}, props.timelineProps, { items: props.timelineItems, containerStyle: reactNative.StyleSheet.flatten([
                    styles.timelineContainerStyle,
                    props.timelineContainerStyle,
                ]), align: props.timelineAlign }))));
    };
    const renderImageBackground = () => {
        var _a;
        return (React.createElement(reactImageBackground.ImageBackgroundV, { width: '100%', height: 280, imageBackgroundStyle: reactNative.StyleSheet.flatten([
                styles.imageBackgroundStyle,
                props.imageBackgroundStyle,
            ]), image: (_a = props.backgroundImage) !== null && _a !== void 0 ? _a : BACKGROUND_IMAGE },
            React.createElement(reactFlexboxGridRs.FBGridV, null,
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 24 }, renderShortProfile()),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 24 }, props.timelineItems && renderTimeline()))));
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.containerStyle, props.containerStyle]) }, renderImageBackground()));
};
const TLProfileTemplateV = (props) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(ProfileTemplateInternal, Object.assign({}, props))));
};

exports.ProfileTemplateInternal = ProfileTemplateInternal;
exports.TLProfileTemplateV = TLProfileTemplateV;
