'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var Toggle = _interopDefault(require('rsuite/lib/Toggle'));
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));

function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const styles = reactNative.StyleSheet.create({
    textInlineStyle: { marginRight: 10, marginTop: 10 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
});
const mapper = { toggleStyle: 'style' };
const delete_keys = [
    'containerStyle',
    'inline',
    'onChange',
    'title',
    'titleStyle',
    'checkedColor',
    'uncheckedColor',
    'titleContainerStyle',
    'errorMessage',
    'errorMessageStyle',
];
const ToggleV = (props) => {
    const [value, setValue] = React.useState();
    const [color, setColor] = React.useState();
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const handleOnChange = (checked, event) => {
        props.onChange && props.onChange(checked);
        setColor(checked ? props.checkedColor : props.uncheckedColor);
        value && setValue(value);
    };
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        props.title && (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                props.titleContainerStyle,
                { flexWrap: 'wrap' },
            ]) },
            React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                    props.titleStyle,
                    props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
                ]) }, props.title))),
        React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                {
                    flexDirection: !props.errorMessage ? 'row' : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                },
            ]) },
            React__default.createElement(Toggle, Object.assign({}, _widgetProps, { style: reactNative.StyleSheet.flatten([
                    props.toggleStyle,
                    { backgroundColor: color },
                ]), checked: value, onChange: handleOnChange })),
            props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten([styles.errorMessageStyle, props.errorMessageStyle]) }, props.errorMessage)))));
};

exports.ToggleV = ToggleV;
