"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryParam = void 0;
exports.getQueryParam = function (param, url) {
    var regex = new RegExp("\\b(" + param + "=)\\b");
    var match = url.match(regex);
    if (match) {
        var hasMoreValues = url.indexOf('&');
        var initial = match.index + match[0].length;
        if (hasMoreValues >= 0) {
            return url.substr(initial, hasMoreValues - initial);
        }
        return url.substr(initial);
    }
    return null;
};
