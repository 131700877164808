import ownerDocument from './ownerDocument';
import nodeName from './nodeName';
import { getStyle } from '../style';
export default (function (node) {
  var doc = ownerDocument(node);
  var offsetParent = node && node.offsetParent;

  while (offsetParent && nodeName(node) !== 'html' && getStyle(offsetParent, 'position') === 'static') {
    offsetParent = offsetParent.offsetParent;
  }

  return offsetParent || doc.documentElement;
});