function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import getOffsetParent from './getOffsetParent';
import getOffset from './getOffset';
import { getStyle } from '../style';
import scrollTop from './scrollTop';
import scrollLeft from './scrollLeft';
import nodeName from './nodeName';
export default (function (node, offsetParent) {
  var parentOffset = {
    top: 0,
    left: 0
  };
  var offset = null; // Fixed elements are offset from window (parentOffset = {top:0, left: 0},
  // because it is its only offset parent

  if (getStyle(node, 'position') === 'fixed') {
    offset = node.getBoundingClientRect();
  } else {
    offsetParent = offsetParent || getOffsetParent(node);
    offset = getOffset(node);

    if (nodeName(offsetParent) !== 'html') {
      var nextParentOffset = getOffset(offsetParent);

      if (nextParentOffset) {
        parentOffset.top = nextParentOffset.top;
        parentOffset.left = nextParentOffset.left;
      }
    }

    parentOffset.top += parseInt(getStyle(offsetParent, 'borderTopWidth'), 10) - scrollTop(offsetParent) || 0;
    parentOffset.left += parseInt(getStyle(offsetParent, 'borderLeftWidth'), 10) - scrollLeft(offsetParent) || 0;
  } // Subtract parent offsets and node margins


  if (offset) {
    return _extends({}, offset, {
      top: offset.top - parentOffset.top - (parseInt(getStyle(node, 'marginTop'), 10) || 0),
      left: offset.left - parentOffset.left - (parseInt(getStyle(node, 'marginLeft'), 10) || 0)
    });
  }

  return null;
});