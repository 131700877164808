'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
var reactNativeSvg = require('react-native-svg');
var reactViewportProvider = require('react-viewport-provider');
var titleCase = require('title-case');

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const IdCardTemplateV1Internal = (props) => {
    const { height, width } = reactViewportProvider.useViewport();
    const styles = {
        contianerStyle: {
            backgroundColor: '#ffffff',
        },
        svgStyle: {
            width: '100%',
        },
        headerContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bodyContainerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentContainerStyle: {
            marginLeft: 27,
            justifyContent: 'flex-start',
        },
        overlayContainerStyle: {},
        avatarContainerStyle: {
            backgroundColor: 'transparent',
        },
        titleContainerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 10,
        },
        titleStyle: {
            fontSize: 25,
            paddingVertical: 14,
            fontWeight: '600',
        },
        profileAvatarContainerStyle: {
            backgroundColor: 'transparent',
        },
        nameTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 5,
        },
        idNumberStyle: {
            fontSize: 15,
            fontWeight: 'bold',
        },
        phNumStyle: {
            fontSize: 15,
            fontWeight: 'bold',
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                styles.contianerStyle,
                props.contianerStyle,
                { width: props.width },
            ]) },
            React.createElement(reactNativeSvg.Svg, { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1440 320", style: styles.svgStyle },
                React.createElement(reactNativeSvg.Path, { fill: props.fillDarkColor, "fill-opacity": "1", d: "M0,320L60,314.7C120,309,240,299,360,277.3C480,256,600,224,720,192C840,160,960,128,1080,133.3C1200,139,1320,181,1380,202.7L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z" }),
                React.createElement(reactNativeSvg.Path, { fill: props.fillLightColor, "fill-opacity": "1", d: "M0,224L60,234.7C120,245,240,267,360,250.7C480,235,600,181,720,138.7C840,96,960,64,1080,85.3C1200,107,1320,181,1380,218.7L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z" })),
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                    styles.headerContainerStyle,
                    props.headerContainerStyle,
                ]) },
                React.createElement(AvatarV, { source: props.avatarSrc, size: props.avatarSize, overlayContainerStyle: reactNative.StyleSheet.flatten([
                        styles.overlayContainerStyle,
                        { backgroundColor: props.avatarBgColor },
                    ]), containerStyle: reactNative.StyleSheet.flatten([styles.avatarContainerStyle]) }),
                React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                        styles.titleContainerStyle,
                        props.titleContainerStyle,
                    ]) },
                    React.createElement(TextV, { type: 'title2', style: reactNative.StyleSheet.flatten([
                            styles.titleStyle,
                            props.titleStyle,
                            { color: props.titleColor, fontFamily: props.titleFontType },
                        ]), children: titleCase.titleCase(props.title), numberOfLines: 2 }))),
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                    styles.bodyContainerStyle,
                    props.bodyContainerStyle,
                ]) },
                React.createElement(AvatarV, { source: props.profileSrc, size: props.profileAvatarSize, overlayContainerStyle: reactNative.StyleSheet.flatten([
                        styles.overlayContainerStyle,
                        {
                            borderColor: props.profileBorderColor,
                            backgroundColor: props.profileAvatarBgColor,
                            borderWidth: 2,
                        },
                    ]), containerStyle: reactNative.StyleSheet.flatten([
                        styles.profileAvatarContainerStyle,
                    ]) }),
                React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                        styles.contentContainerStyle,
                        props.contentContainerStyle,
                        { height: props.height },
                    ]) },
                    React.createElement(TextV, { type: 'title2', style: reactNative.StyleSheet.flatten([
                            styles.nameTitleStyle,
                            props.nameTitleStyle,
                            { color: props.titleColor },
                        ]), children: titleCase.titleCase(props.name), numberOfLines: 2 }),
                    React.createElement(TextV, { type: 'title2', style: reactNative.StyleSheet.flatten([
                            styles.idNumberStyle,
                            props.idNumberStyle,
                            { color: props.idColor },
                        ]), children: `ID ${props.id}`, numberOfLines: 1 }),
                    React.createElement(TextV, { type: 'title1', style: reactNative.StyleSheet.flatten([
                            styles.phNumStyle,
                            props.phNumStyle,
                            { color: props.phNumColor },
                        ]), children: `Contact ${props.phoneNumber}`, numberOfLines: 1 })),
                React.createElement(reactNativeSvg.Svg, { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1440 320", style: styles.svgStyle },
                    React.createElement(reactNativeSvg.Path, { fill: props.fillLightColor, "fill-opacity": "1", d: "M0,192L60,160C120,128,240,64,360,37.3C480,11,600,21,720,53.3C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z" }),
                    React.createElement(reactNativeSvg.Path, { fill: props.fillDarkColor, "fill-opacity": "1", d: "M0,256L60,218.7C120,181,240,107,360,96C480,85,600,139,720,176C840,213,960,235,1080,213.3C1200,192,1320,128,1380,96L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z" }))))));
};
const IdCardTemplateV1 = (props) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(IdCardTemplateV1Internal, Object.assign({}, props))));
};
IdCardTemplateV1.defaultProps = {
    fillDarkColor: '#000b76',
    fillLightColor: '#1e90ff',
    avatarSrc: { uri: 'https://i.ibb.co/Xb2Pq4M/logo.png' },
    avatarSize: 90,
    avatarBgColor: '#fff',
    title: 'Vlinder',
    titleColor: '#000',
    titleFontType: 'conthrax-sb',
    profileSrc: 'https://i.ibb.co/Nt902Lm/pexels-photo-220453.jpg',
    profileAvatarSize: 150,
    profileBorderColor: '#1e90ff',
    name: 'Chris Jackson',
    id: '999',
    phoneNumber: '+91 9876543210',
    height: 150,
    width: '25%',
};

exports.IdCardTemplateV1 = IdCardTemplateV1;
