//@ts-nocheck
import React from 'react';
import {View, ViewStyle, StyleSheet, ImageSourcePropType} from 'react-native';
import QRCode from 'react-native-qrcode-svg';

export enum ECL {
  L = 'L',
  M = 'M',
  Q = 'Q',
  H = 'H',
}

interface AppProps {
  containerStyle?: ViewStyle;
  size: number;
  value: string;
  color: string;
  backgroundColor: string;
  enableLinearGradient: boolean;
  linearGradient: string[];
  gradientDirection: any[];
  logo: ImageSourcePropType;
  logoSize: number;
  logoBackgroundColor: string;
  logoMargin: number;
  logoBorderRadius: number;
  quietZone: number;
  ecl: ECL;
  onError?(error: any): void;
}

export const QRCodeGeneratorV = (props: AppProps): JSX.Element => {
  const {
    containerStyle,
    size,
    value,
    color,
    backgroundColor,
    enableLinearGradient,
    linearGradient,
    gradientDirection,
    logo,
    logoSize,
    logoBackgroundColor,
    logoMargin,
    logoBorderRadius,
    quietZone,
    ecl,
    onError,
  } = props;
  return (
    <View style={StyleSheet.flatten([containerStyle && containerStyle])}>
      <QRCode
        size={size}
        value={value}
        color={color}
        backgroundColor={backgroundColor}
        enableLinearGradient={enableLinearGradient}
        linearGradient={linearGradient}
        gradientDirection={gradientDirection}
        logo={logo}
        logoSize={logoSize}
        logoBackgroundColor={logoBackgroundColor}
        logoMargin={logoMargin}
        logoBorderRadius={logoBorderRadius}
        quietZone={quietZone}
        ecl={ecl}
        onError={onError}
      />
    </View>
  );
};

QRCodeGeneratorV.defaultProps = {
  size: 100,
  value: 'Hello vlinder',
  color: 'black',
  backgroundColor: 'white',
  enableLinearGradient: false,
  linearGradient: ['rgb(255,0,0)', 'rgb(0,255,255)'],
  gradientDirection: [170, 0, 0, 0],
  logo: null,
  logoBackgroundColor: 'white',
  logoMargin: 2,
  logoBorderRadius: 0,
  quietZone: 0,
  ecl: ECL.M,
  onError: undefined,
} as Partial<AppProps>;
