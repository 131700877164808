'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var hexToRgba = _interopDefault(require('hex-to-rgba'));
var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactLogoSpinnerExt = require('react-logo-spinner-ext');
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
var reactViewportProvider = require('react-viewport-provider');
var Button = _interopDefault(require('rsuite/lib/Button'));
var ButtonGroup = _interopDefault(require('rsuite/lib/ButtonGroup'));
var Container = _interopDefault(require('rsuite/lib/Container'));
var Content = _interopDefault(require('rsuite/lib/Content'));
var Footer = _interopDefault(require('rsuite/lib/Footer'));
var Header = _interopDefault(require('rsuite/lib/Header'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var Panel = _interopDefault(require('rsuite/lib/Panel'));
var Steps = _interopDefault(require('rsuite/lib/Steps'));

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const CONTENT_BACKGROUND_COLOR = '#FFFFFF';
const STEPPER_BACKGROUND_COLOR = '#f05776';
const SUBTITLE_COLOR = '#636669';
const STEPPER_ACTIVE_TITLE_COLOR = 'rgba(255,255,255,0.8)';
const STEPPER_DISABLE_TITLE_COLOR = hexToRgba('#FFFFFF', 0.3);
const STEPPER_ICON_ACTIVE_COLOR = STEPPER_BACKGROUND_COLOR;
const STEPPER_ICON_DISABLE_COLOR = 'rgba(255,255,255,0.8)';
const STEPPER_ICON_BG_ACTIVE_COLOR = 'rgba(255,255,255,0.8)';
const STEPPER_ICON_BG_DISABLE_COLOR = hexToRgba('#FFFFFF', 0.2);
const TOTAL_COL_SPAN = 24;
const SMALL_DEVICES_STEP_COL_SPAN = 4;
const StepTemplateInternal = (props) => {
    var _a, _b, _c, _d, _e;
    // const [step, setStep] = React.useState(0);
    // const [disabledStep, setDisabledStep] = React.useState<number | undefined>();
    const { bp } = reactViewportProvider.useViewport();
    const step = props.step < 0 ? 0 : props.step >= (props.items && props.items.length) ? props.items.length - 1 : props.step;
    React.useEffect(() => {
        if (props.parentRef)
            props.parentRef.current = step;
    }, [props.step]);
    const onNext = () => {
        var _a;
        if (props.onNext && props.items && Array.isArray(props.items)) {
            // if(!props.controlled) {
            //     const next = props.onNext(
            //       step,
            //       props.items[step] && props.items[step].key,
            //     );
            //     if (next) {
            //       onChange(step + 1);
            //     } else {
            //       setDisabledStep(step);
            //     }
            // }
            // else {
            // console.log("current step", step)
            props.onNext && props.onNext(step, (_a = props.items[step]) === null || _a === void 0 ? void 0 : _a.key);
            // }
        }
        // else {
        //   if (!props.controlled) onChange(step + 1);
        // }
    };
    const onPrevious = () => {
        var _a;
        if (props.onPrev && props.items && Array.isArray(props.items)) {
            // const prev = props.onPrev(step, props.items[step] && props.items[step].key);
            // if (prev) {
            //   onChange(step - 1);
            // }
            // else {
            //   setDisabledStep(step);
            // }
            //  console.log('current step', step);
            props.onPrev && props.onPrev(step, (_a = props.items[step]) === null || _a === void 0 ? void 0 : _a.key);
        }
        // else {
        //   onChange(step - 1);
        // }
    };
    const styles = {
        containerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
        },
        panelStyle: {
            display: 'inline-block',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        stepperContainerStyle: {
            backgroundColor: (_a = props.stepperBackgroundColor) !== null && _a !== void 0 ? _a : STEPPER_BACKGROUND_COLOR,
            height: (_b = props.height) !== null && _b !== void 0 ? _b : 500,
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: 50,
        },
        contentContainerStyle: {
            backgroundColor: (_c = props.contentBackgroundColor) !== null && _c !== void 0 ? _c : CONTENT_BACKGROUND_COLOR,
            height: (_d = props.height) !== null && _d !== void 0 ? _d : 500,
        },
        stepperItemStyle: {
            color: 'transparent',
        },
        iconContainerStyle: {
            width: 30,
            height: 30,
            borderRadius: 15,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 8,
        },
        stepperTitleStyle: {
            fontSize: 15,
            fontWeight: 600,
            lineSpacing: 2,
            letterSpacing: 1,
            alignSelf: 'center',
            textAlign: 'center',
            marginLeft: 10,
        },
        stepperButtonContainer: {
            marginBottom: 40,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginRight: 40,
        },
        headerContainerStyle: {
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: 30,
            paddingTop: 45,
        },
        headerTitleStyle: {
            fontSize: 18,
            fontWeight: 600,
            lineSpacing: 2,
            letterSpacing: 1,
            textAlign: 'center',
            color: SUBTITLE_COLOR,
        },
        bodyContentStyle: {
            overflowY: 'scroll',
        },
    };
    const getBodyColSpan = () => {
        let span = 18;
        if (props.stepperColSpan && props.stepperColSpan < TOTAL_COL_SPAN) {
            span = TOTAL_COL_SPAN - props.stepperColSpan;
        }
        return span;
    };
    const getStepperColSpan = () => {
        var _a;
        return (_a = props.stepperColSpan) !== null && _a !== void 0 ? _a : 6;
    };
    const renderBody = () => {
        return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'start', align: 'top' },
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: bp !== 'xs' && bp !== 'sm' ? getStepperColSpan() : SMALL_DEVICES_STEP_COL_SPAN },
                React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.stepperContainerStyle]) }, renderSteps())),
            React.createElement(reactFlexboxGridRs.FBItemV, { colspan: bp !== 'xs' && bp !== 'sm' ? getBodyColSpan() : TOTAL_COL_SPAN - SMALL_DEVICES_STEP_COL_SPAN },
                React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.contentContainerStyle]) }, renderStepperBodyContent()))));
    };
    const renderStepperBodyContent = () => {
        var _a, _b, _c;
        if (props.loading) {
            if (props.renderLoading) {
                return props.renderLoading();
            }
            return React.createElement(reactLogoSpinnerExt.LogoSpinnerV, Object.assign({}, props));
        }
        return (React.createElement(Container, { style: reactNative.StyleSheet.flatten([styles.bodyContentStyle]) },
            ((_a = props.items[step]) === null || _a === void 0 ? void 0 : _a.title) && props.headerTitleToDisplay && (React.createElement(Header, null, renderHeaderTitle((_b = props.items[step]) === null || _b === void 0 ? void 0 : _b.title))),
            React.createElement(Content, null, props.renderStepperContent && props.renderStepperContent((_c = props.items[step]) === null || _c === void 0 ? void 0 : _c.key)),
            React.createElement(Footer, null, renderStepperButton())));
    };
    const renderHeaderTitle = (title) => {
        if (title) {
            return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.headerContainerStyle]) },
                React.createElement(TextV, { type: 'title3', style: reactNative.StyleSheet.flatten([styles.headerTitleStyle]) }, title)));
        }
        return null;
    };
    const renderStepperButton = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.stepperButtonContainer]) },
            React.createElement(ButtonGroup, null,
                step !== props.items.length - 1 && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: onPrevious, disabled: step === 0 || !props.enablePrevonCurrentStep, style: {
                            backgroundColor: step === 0 ? '#d3d3d3' : (_a = props.stepperBackgroundColor) !== null && _a !== void 0 ? _a : STEPPER_BACKGROUND_COLOR,
                            color: (_b = props.stepperTitleActiveColor) !== null && _b !== void 0 ? _b : '#ffffff',
                        } }, (_c = props.stepperPrevTitle) !== null && _c !== void 0 ? _c : 'Previous'),
                    React.createElement(Button, { onClick: onNext, style: {
                            backgroundColor: step === (props.items.length ? props.items.length - 1 : props.items.length)
                                ? '#d3d3d3'
                                : (_d = props.stepperBackgroundColor) !== null && _d !== void 0 ? _d : STEPPER_BACKGROUND_COLOR,
                            color: (_e = props.stepperTitleActiveColor) !== null && _e !== void 0 ? _e : '#ffffff',
                        }, disabled: !props.enableNextonCurrentStep ||
                            step === (props.items.length ? props.items.length - 1 : props.items.length) }, (_f = props.stepperNextTitle) !== null && _f !== void 0 ? _f : 'Next'))),
                step === props.items.length - 1 && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: onPrevious, disabled: step === 0, style: {
                            backgroundColor: '#d3d3d3',
                            color: '#ffffff',
                        } }, (_g = props.stepperPrevTitle) !== null && _g !== void 0 ? _g : 'Previous'),
                    React.createElement(Button, { onClick: props.onStepperDonePress, style: {
                            backgroundColor: (_h = props.stepperBackgroundColor) !== null && _h !== void 0 ? _h : STEPPER_BACKGROUND_COLOR,
                            color: (_j = props.stepperTitleActiveColor) !== null && _j !== void 0 ? _j : '#ffffff',
                        }, disable: !props.enableNextonCurrentStep }, (_k = props.stepperDoneTitle) !== null && _k !== void 0 ? _k : 'Complete'))))));
    };
    const renderIcon = (icon, index = 0) => {
        var _a, _b, _c, _d;
        if (icon) {
            return (React.createElement(Icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, size: icon === null || icon === void 0 ? void 0 : icon.size, style: reactNative.StyleSheet.flatten([
                    styles.iconContainerStyle,
                    (icon === null || icon === void 0 ? void 0 : icon.iconStyle) && Object.assign({}, icon === null || icon === void 0 ? void 0 : icon.iconStyle),
                    {
                        backgroundColor: step === index
                            ? (_a = props.stepperIconBackgroundActiveColor) !== null && _a !== void 0 ? _a : STEPPER_ICON_BG_ACTIVE_COLOR : (_b = props.stepperIconBackgroundDisableColor) !== null && _b !== void 0 ? _b : STEPPER_ICON_BG_DISABLE_COLOR,
                        color: step === index
                            ? (_c = props.stepperIconActiveColor) !== null && _c !== void 0 ? _c : STEPPER_ICON_ACTIVE_COLOR : (_d = props.stepperIconDisableColor) !== null && _d !== void 0 ? _d : STEPPER_ICON_DISABLE_COLOR,
                    },
                ]), fixedWidth: true }));
        }
        return undefined;
    };
    const renderTitle = (title, index = 0, description = false) => {
        var _a, _b;
        if (title && bp !== 'sm' && bp !== 'xs' && !props.stepIconOnly) {
            return (React.createElement(TextV, { type: 'callout', numberOfLines: 1, style: reactNative.StyleSheet.flatten([
                    styles.stepperTitleStyle,
                    {
                        color: step === index
                            ? (_a = props.stepperTitleActiveColor) !== null && _a !== void 0 ? _a : STEPPER_ACTIVE_TITLE_COLOR : (_b = props.stepperTitleDisableColor) !== null && _b !== void 0 ? _b : STEPPER_DISABLE_TITLE_COLOR,
                        fontSize: description ? 10 : 15,
                    },
                ]) }, title));
        }
        return undefined;
    };
    const renderSteps = () => {
        if (props.items && Array.isArray(props.items)) {
            return (React.createElement(Steps, { current: step, small: true, vertical: true, style: reactNative.StyleSheet.flatten([styles.stepperItemStyle]) }, props.items.map((item, index) => {
                return (React.createElement(Steps.Item, { key: index, title: renderTitle(item === null || item === void 0 ? void 0 : item.title, index), description: renderTitle(item === null || item === void 0 ? void 0 : item.description, index, true), status: item === null || item === void 0 ? void 0 : item.status, icon: renderIcon(item === null || item === void 0 ? void 0 : item.icon, index), style: reactNative.StyleSheet.flatten([styles.stepperItemStyle]) }));
            })));
        }
        return null;
    };
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                styles.containerStyle,
                props.containerStyle && props.containerStyle,
                props.width && { width: props.width },
                props.height && { height: props.height },
                props.backgroundColor && { backgroundColor: props.backgroundColor },
            ]) },
            React.createElement(Panel, { bodyFill: (_e = props.bodyFill) !== null && _e !== void 0 ? _e : true, bordered: props.bordered, shaded: props.shaded, style: reactNative.StyleSheet.flatten([styles.panelStyle, props.panelStyle]) }, renderBody()))));
};
const StepTemplateV = React.forwardRef((props, ref) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(StepTemplateInternal, Object.assign({}, props, { parentRef: ref }))));
});

exports.StepTemplateV = StepTemplateV;
