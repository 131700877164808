/**
 * Unbind `target` event `eventName`'s callback `listener`.
 *
 * @param {Element} target
 * @param {String} eventName
 * @param {Function} listener
 * @param {Boolean} capture
 * @api public
 */
export default (function (target, eventName, listener, capture) {
  if (capture === void 0) {
    capture = false;
  }

  target.removeEventListener(eventName, listener, capture);
});