"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringTemplate = void 0;
exports.stringTemplate = function (template, isPrefix) {
    if (typeof template === 'object') {
        return function (value, index) {
            if (index > template.length - 1) {
                return value;
            }
            return isPrefix ? template[index] + value : value + template[index];
        };
    }
    return function (value) { return (isPrefix ? template + value : value + template); };
};
