'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var noImportant = require('aphrodite/no-important');
var React = _interopDefault(require('react'));
var reactViewportProvider = require('react-viewport-provider');

//@ts-nocheck
const rotate = {
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
};
const LogoSpinnerInternal = (props) => {
    var _a;
    const { width, height } = reactViewportProvider.useViewport();
    const defaultUri = 'https://i.ibb.co/MZLXLNf/logo.png';
    const getStyle = () => {
        var _a;
        let _style = (_a = props.imageStyle) !== null && _a !== void 0 ? _a : {};
        const style = { position: 'fixed', top: 0.4 * height };
        if (props.align === 'top') {
            style.top = 0.1 * height;
            return Object.assign(Object.assign({}, style), _style);
        }
        if (props.align === 'middle') {
            return Object.assign(Object.assign({}, style), _style);
        }
        if (props.align === 'bottom') {
            style.top = 0.8 * height;
            return Object.assign(Object.assign({}, style), _style);
        }
        if (typeof props.align === 'number' && props.align <= 1) {
            style.top = props.align * height;
            return Object.assign(Object.assign({}, style), _style);
        }
        if (props.justify === 'left') {
            style.left = '10%';
            return Object.assign(Object.assign({}, style), _style);
        }
        if (props.justify === 'center') {
            return Object.assign(Object.assign({}, style), _style);
        }
        if (props.justify === 'right') {
            style.right = '10%';
            return Object.assign(Object.assign({}, style), _style);
        }
        if (typeof props.justify === 'number' && props.justify <= 1) {
            style.left = props.justify * width;
            return Object.assign(Object.assign({}, style), _style);
        }
        return Object.assign(Object.assign({}, style), _style);
    };
    const styles = noImportant.StyleSheet.create({
        imageStyle: {
            animationName: rotate,
            animationDuration: '2s',
            animationDirection: 'normal',
            animationIterationCount: 'infinite',
            width: 80,
            height: 80,
            justifyContent: 'center',
            alignSelf: 'center',
            flexDirection: 'column',
        },
    });
    return (React.createElement("img", { className: noImportant.css(styles.imageStyle), style: getStyle(), 
        // style={StyleSheet.flatten([styles.imageStyle, props.imageStyle])}
        src: (_a = props.uri) !== null && _a !== void 0 ? _a : defaultUri }));
};
const LogoSpinnerV = (props) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(LogoSpinnerInternal, Object.assign({}, props))));
};

exports.LogoSpinnerV = LogoSpinnerV;
