'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');

const styles = {
    backgroundImageStyle: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top-position',
    },
};
const ImageBackgroundV = (props) => {
    return (React.createElement("div", { style: reactNative.StyleSheet.flatten([
            styles.backgroundImageStyle,
            props.imageBackgroundStyle,
            props.width && { width: props.width },
            props.height && { height: props.height },
            { backgroundImage: `url(${props.image})` },
        ]) }, props.children));
};

exports.ImageBackgroundV = ImageBackgroundV;
