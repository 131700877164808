'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = require('react');
var React__default = _interopDefault(React);
var reactButtonRs = require('react-button-rs');
var reactIconRs = require('react-icon-rs');
var reactNative = require('react-native');
var reactPanelRs = require('react-panel-rs');
require('rsuite/dist/styles/rsuite-default.css');
var Button = _interopDefault(require('rsuite/lib/Button'));
var Steps = _interopDefault(require('rsuite/lib/Steps'));

//@ts-nocheck
const ids = require('short-id');
const styles = {
    verticalStyle: {
        display: 'flex',
    },
    stepStyle: {
        padding: 20,
    },
    panelStyle: {
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'space-between',
    },
    buttonGroupStyle: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        alignItems: 'flex-end'
    }
};
const StepV = (props) => {
    const { steps, vertical, bodyContent, containerStyle, buttonStyle, buttonGroupStyle, prevButtonText, nextButtonText, } = props;
    const [step, setStep] = React.useState(0);
    const onChange = (nextStep) => {
        setStep(nextStep < 0 ? 0 : nextStep > steps.length ? steps.length : nextStep);
        _getChildrenData(nextStep);
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
    const _getChildrenData = stepNo => {
        if (stepNo) {
            return bodyContent === null || bodyContent === void 0 ? void 0 : bodyContent(stepNo);
        }
        else {
            return bodyContent === null || bodyContent === void 0 ? void 0 : bodyContent(step);
        }
    };
    const _getSteps = () => {
        const stepsTitle = [];
        if (steps && Array.isArray(steps)) {
            steps.forEach((element, index) => {
                stepsTitle.push(React__default.createElement(Steps.Item, { key: ids.generate(), title: element.title, description: element.description, icon: element.icon ? React__default.createElement(reactIconRs.IconV, { icons: element.icon }) : null }));
            });
        }
        return stepsTitle;
    };
    return (React__default.createElement("div", { style: reactNative.StyleSheet.flatten([containerStyle, styles.containerStyle]) }, vertical ? (React__default.createElement("div", { style: reactNative.StyleSheet.flatten([styles.verticalStyle]) },
        React__default.createElement(Steps, { current: step, vertical: vertical, style: reactNative.StyleSheet.flatten([props.style, styles.stepStyle]) }, _getSteps()),
        React__default.createElement(reactPanelRs.PanelV, { style: reactNative.StyleSheet.flatten([styles.panelStyle]) },
            _getChildrenData(),
            React__default.createElement(reactButtonRs.ButtonGroupV, { containerStyle: reactNative.StyleSheet.flatten([buttonGroupStyle]) },
                React__default.createElement(Button, { style: reactNative.StyleSheet.flatten([buttonStyle]), onClick: onPrevious, disabled: (props.isPrevEnabled && !props.isPrevEnabled) || step === 0, color: props.activeColor }, prevButtonText ? prevButtonText : 'Prev'),
                React__default.createElement(Button, { onClick: onNext, buttonStyle: reactNative.StyleSheet.flatten([buttonStyle]), disabled: (step === steps.length) || (props.isNextEnabled && !props.isNextEnabled), color: props.activeColor }, nextButtonText ? nextButtonText : 'Next'))))) : (React__default.createElement("div", null,
        React__default.createElement(Steps, { current: step, vertical: vertical, style: reactNative.StyleSheet.flatten([props.style, styles.stepStyle]) }, _getSteps()),
        React__default.createElement(reactPanelRs.PanelV, null,
            _getChildrenData(),
            React__default.createElement(reactButtonRs.ButtonGroupV, { containerStyle: reactNative.StyleSheet.flatten([buttonGroupStyle]) },
                React__default.createElement(Button, { style: reactNative.StyleSheet.flatten([buttonStyle]), onClick: onPrevious, disabled: (props.isPrevEnabled && !props.isPrevEnabled) || step === 0, color: props.activeColor }, prevButtonText ? prevButtonText : 'Prev'),
                React__default.createElement(Button, { onClick: onNext, buttonStyle: reactNative.StyleSheet.flatten([buttonStyle]), disabled: (step === steps.length) || (props.isNextEnabled && !props.isNextEnabled), color: props.activeColor }, nextButtonText ? nextButtonText : 'Next')))))));
};

exports.StepV = StepV;
