'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var Checkbox = _interopDefault(require('rsuite/lib/Checkbox'));
var CheckboxGroup = _interopDefault(require('rsuite/lib/CheckboxGroup'));
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));

var ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
//const mapper = {name: 'icon'};
const delete_checkboxgroups_keys = [
    'containerStyle',
    'items',
    'onChange',
    'title',
    'titleStyle',
    'errorMessage',
    'errorMessageStyle',
];
const delete_checkbox_keys = ['label'];
const styles = reactNative.StyleSheet.create({
    textInlineStyle: { marginRight: 10, marginTop: 10 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
});
const CheckboxV = (props) => {
    const [value, setValue] = React.useState();
    const checkboxgroupProps = Object.assign({}, props);
    deleteKeys(checkboxgroupProps, delete_checkboxgroups_keys);
    const handleOnChange = (value, event) => {
        props.onChange && props.onChange(value);
        value && setValue([...value]);
    };
    const renderErrorMessage = () => {
        if (props.errorMessage) {
            return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                    {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    },
                ]) },
                React__default.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten([
                        styles.errorMessageStyle,
                        props.errorMessageStyle,
                    ]) }, props.errorMessage)));
        }
        return null;
    };
    const renderTitle = () => {
        if (props.title) {
            return (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([props.titleStyle, { flexWrap: 'wrap' }]) }, props.title));
        }
        return null;
    };
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        React__default.createElement(CheckboxGroup, Object.assign({}, checkboxgroupProps, { onChange: handleOnChange, value: value }),
            renderTitle(),
            props.items &&
                Array.isArray(props.items) &&
                props.items.map((item, index) => {
                    const _props = Object.assign({}, item);
                    _props && deleteKeys(_props, delete_checkbox_keys);
                    return (React__default.createElement(Checkbox, Object.assign({}, _props, { key: ids.generate(), index: index, style: { borderColor: 'red' } }), item === null || item === void 0 ? void 0 : item.label));
                }),
            renderErrorMessage())));
};

exports.CheckboxV = CheckboxV;
