'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');
require('rsuite/dist/styles/rsuite-default.css');
var Badge = _interopDefault(require('rsuite/lib/Badge'));

const BadgeV = (props) => {
    return React.createElement(Badge, Object.assign({}, props, { style: reactNative.StyleSheet.flatten([props.badgeStyle]) }));
};

exports.BadgeV = BadgeV;
