'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var Nav = _interopDefault(require('@rsuite/responsive-nav'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var _ = _interopDefault(require('lodash'));

//@ts-nocheck
var ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
//const mapper = {name: 'icon'};
const delete_keys = ['items', 'renderItem', 'activeKey', 'onSelect', 'activeColor'];
const delete_nav_item_keys = ['itemType', 'icon', 'children'];
const NavV = (props) => {
    const [active, setActive] = React.useState(props.activeKey);
    const [items, setItems] = React.useState(props.items ? [...props.items] : []);
    const _navProps = Object.assign({}, props);
    deleteKeys(_navProps, delete_keys);
    const handleSelect = (activeKey) => {
        props.onSelect && props.onSelect(activeKey);
        setActive(activeKey);
    };
    const renderIcon = (icon, activeKey) => {
        if (icon) {
            return (React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([icon.style, active === activeKey && { color: props.activeColor }]) })));
        }
        return null;
    };
    const handleOnItemRemove = (activeKey) => {
        if (items && Array.isArray(items)) {
            const _items = [...items];
            _.remove(_items, { eventKey: activeKey });
            props.onItemRemove && props.onItemRemove(activeKey);
            setItems([
                ..._items
            ]);
        }
    };
    const renderItems = (items) => {
        let _res = [];
        if (items && Array.isArray(items)) {
            items.forEach((item, index) => {
                const _item = item ? Object.assign({}, item) : {};
                deleteKeys(_item, delete_nav_item_keys);
                if (item.itemType !== 'dropdown') {
                    _res.push(React__default.createElement(Nav.Item, Object.assign({ key: ids.generate() }, _item, { renderItem: props.renderItem, icon: renderIcon(item.icon, item.eventKey) }),
                        React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                                active === item.eventKey && { color: props.activeColor },
                            ]) }, item.label)));
                }
            });
        }
        return _res;
    };
    return (React__default.createElement(Nav, Object.assign({}, _navProps, { activeKey: active, onSelect: handleSelect, onItemRemove: handleOnItemRemove, style: { color: 'red' } }), renderItems(items)));
};

exports.NavV = NavV;
