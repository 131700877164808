'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var reactImageBackground = require('react-image-background');
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactNativeElements = require('react-native-elements');
var expoLinearGradient = require('expo-linear-gradient');
var Divider = _interopDefault(require('rsuite/lib/Divider'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var Whisper = _interopDefault(require('rsuite/lib/Whisper'));
var Tooltip = _interopDefault(require('rsuite/lib/Tooltip'));

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

var ButtonType;
(function (ButtonType) {
    ButtonType["Solid"] = "solid";
    ButtonType["Clear"] = "clear";
    ButtonType["Outline"] = "outline";
})(ButtonType || (ButtonType = {}));
const ButtonV = (props) => {
    const { buttonStyle, containerStyle, disabled, disabledStyle, disabledTitleStyle, icon, iconContainerStyle, iconRight, linearGradientProps, loading, loadingProps, loadingStyle, onPress, raised, title, titleProps, titleStyle, type, TouchableComponent, ViewComponent, } = props;
    return (React.createElement(reactNativeElements.Button, { buttonStyle: buttonStyle && buttonStyle, containerStyle: containerStyle && containerStyle, disabled: disabled, disabledStyle: disabledStyle && disabledStyle, disabledTitleStyle: disabledTitleStyle && disabledTitleStyle, icon: icon && icon, iconContainerStyle: iconContainerStyle && iconContainerStyle, iconRight: iconRight && iconRight, linearGradientProps: linearGradientProps && linearGradientProps, loading: loading, loadingProps: loadingProps && loadingProps, loadingStyle: loadingStyle && loadingStyle, onPress: onPress && onPress, raised: raised && raised, title: title && title, titleProps: titleProps && titleProps, titleStyle: titleStyle && titleStyle, type: type && type, TouchableComponent: TouchableComponent, ViewComponent: linearGradientProps
            ? expoLinearGradient.LinearGradient
            : ViewComponent
                ? ViewComponent
                : reactNative.View }));
};

//@ts-nocheck
const ids = require('short-id');
const AVATAR_WIDTH = 80;
const AVATAR_HEIGHT = 80;
const TITLE_FONT_COLOR = '#333333';
const PROFILE_ICON_COLOR = '#333333';
const styles$1 = {
    avatarContainerStyle: {
        width: AVATAR_WIDTH,
        height: AVATAR_HEIGHT,
    },
    titleContainerStyle: {
        height: 100,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    titleStyle: {
        color: TITLE_FONT_COLOR,
    },
    subtitleStyle: {
        color: TITLE_FONT_COLOR,
        marginLeft: 5,
    },
    avatarTitleContainerStyle: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 15,
    },
    headerButtonStyle: {
        width: 120,
        borderRadius: 0,
        alignSelf: 'center',
    },
    headerButtonContainerStyle: {
        alignItems: 'center',
    },
    headerButtonTitleStyle: {
        fontWeight: 500,
    },
    profileContainer: {
        width: '80%',
    },
    profileItemContainer: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 30,
        marginBottom: 25,
    },
    profileIconStyle: {
        color: PROFILE_ICON_COLOR,
        alignSelf: 'center',
        width: 10,
    },
    profileTitleGroupContainer: {
        marginLeft: 30,
        alignItems: 'flex-start',
        justifyContent: 'center',
    }
};
const OrganizationProfileV = (props) => {
    const renderBody = () => {
        const _body = [];
        if (props.title) {
            _body.push(renderHeader());
            _body.push(React.createElement(Divider, { key: ids.generate() }));
            _body.push(renderProfileInfo());
        }
        return _body;
    };
    const renderProfileInfo = () => {
        if (props.profileDetails && Array.isArray(props.profileDetails)) {
            const _profileDetails = props.profileDetails;
            return (React.createElement(reactFlexboxGridRs.FBGridV, { style: reactNative.StyleSheet.flatten([styles$1.profileContainer]), justify: 'space-between' }, renderProfileItems(_profileDetails)));
        }
        return null;
    };
    const renderProfileItems = (profile) => {
        let _profile = [];
        if (profile && Array.isArray(profile)) {
            profile.forEach((prof, item) => {
                var _a, _b, _c, _d, _e;
                _profile.push(React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 12 },
                    React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles$1.profileItemContainer]) },
                        prof.icon && (React.createElement(Whisper, { placement: "top", trigger: "hover", speaker: React.createElement(Tooltip, null, (_a = prof.tooltip) !== null && _a !== void 0 ? _a : prof.title) },
                            React.createElement(Icon, { icon: (_b = prof.icon) === null || _b === void 0 ? void 0 : _b.name, size: (_c = prof.icon) === null || _c === void 0 ? void 0 : _c.size, style: reactNative.StyleSheet.flatten([
                                    styles$1.profileIconStyle,
                                    ((_d = prof.icon) === null || _d === void 0 ? void 0 : _d.iconStyle) && Object.assign({}, (_e = prof.icon) === null || _e === void 0 ? void 0 : _e.iconStyle),
                                ]), fixedWidth: true }))),
                        prof.title && (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                                styles$1.profileTitleGroupContainer,
                            ]) },
                            React.createElement(TextV, { type: 'footnote' }, prof.type === 'url' ? (React.createElement("a", { href: prof.title, target: '_blank', style: { color: TITLE_FONT_COLOR } }, prof.title)) : (prof.title)),
                            prof.subtitle1 && (React.createElement(TextV, { type: 'footnote' }, prof.subtitle1)),
                            prof.subtitle2 && (React.createElement(TextV, { type: 'footnote' }, prof.subtitle2)))))));
            });
        }
        return _profile;
    };
    const renderHeader = () => {
        if (props.title) {
            return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'space-between', style: reactNative.StyleSheet.flatten([styles$1.titleContainerStyle]) },
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 18 },
                    React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles$1.avatarTitleContainerStyle]) },
                        props.leftAvatar && renderAvatar(props.leftAvatar),
                        props.title && React.createElement(reactNative.View, null, renderTitle(props.title)))),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 6 }, props.headerButtonTitle && renderButton())));
        }
        return null;
    };
    const renderAvatar = (avatar) => {
        if (avatar) {
            return (React.createElement(AvatarV, { containerStyle: reactNative.StyleSheet.flatten([styles$1.avatarContainerStyle]), size: AVATAR_WIDTH, rounded: true, source: { uri: avatar } }));
        }
        return null;
    };
    const renderButton = () => {
        if (props.headerButtonTitle) {
            return (React.createElement(ButtonV, { title: props.headerButtonTitle, buttonStyle: reactNative.StyleSheet.flatten([styles$1.headerButtonStyle,
                    props.headerButtonStyle,
                    props.headerButtonColor && { backgroundColor: props.headerButtonColor }]), type: ButtonType.Solid, titleStyle: reactNative.StyleSheet.flatten([styles$1.headerButtonTitleStyle, props.headerButtonTitleStyle]), containerStyle: reactNative.StyleSheet.flatten([styles$1.headerButtonContainerStyle]), onPress: () => { props.onHeaderButtonPress && props.onHeaderButtonPress(props.headerButtonId); } }));
        }
        return null;
    };
    const renderTitle = (title) => {
        const _title = [];
        if (title) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'title1', style: reactNative.StyleSheet.flatten([styles$1.titleStyle, props.titleStyle, props.titleColor && { color: props.titleColor }]) }, String(title).toUpperCase()));
        }
        if (props.subtitle) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'callout', style: reactNative.StyleSheet.flatten([
                    styles$1.subtitleStyle,
                    props.subtitleStyle,
                    props.subtitleColor && { color: props.subtitleColor },
                ]) }, String(props.subtitle).toLowerCase()));
        }
        return _title;
    };
    return (React.createElement(reactImageBackground.ImageBackgroundV, { image: props.image, width: props.width, height: props.height, imageBackgroundStyle: props.imageBackgroundStyle }, renderBody()));
};

exports.OrganizationProfileV = OrganizationProfileV;
