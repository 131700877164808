'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
require('rsuite/dist/styles/rsuite-default.css');
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var TimeLine = _interopDefault(require('rsuite/lib/TimeLine'));

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const ids = require('short-id');
const getTextAlign = (align) => {
    if (align) {
        if (align === 'alternate') {
            return 'right';
        }
        return align;
    }
    return 'center';
};
const TimeLineV = (props) => {
    const styles = reactNative.StyleSheet.create({
        timeLineStyle: {
            marginLeft: '20px',
        },
        iconContainerStyle: {
            position: 'absolute',
            backgroundColor: ' #fff',
            top: 0,
            left: -2,
            // border: '2px solid #ddd',
            width: 40,
            height: 40,
            borderRadius: '50%',
            borderWidth: 2,
            borderColor: '#ddd',
            fontSize: 18,
            paddingTop: 9,
            color: '#999',
            marginLeft: -13,
        },
        iconStyle: {},
        containerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
        },
        paragraghContainer: {
            width: '92%',
        },
        paragraphStyle: {
            textAlign: getTextAlign(props.align),
        },
        timelineText: {},
        timeContainer: {
            width: 100,
            alignItems: 'center',
            justifyContent: 'center',
        },
        dotAvatarContainerStyle: {
            position: 'absolute',
            top: 0,
            left: -2,
            width: 40,
            height: 40,
            paddingTop: 9,
            marginLeft: -13,
            borderRadius: 20,
            alignItems: 'center',
            justifyContent: 'center',
        },
        dotAvatarStyle: {
            width: 32,
            height: 32,
            borderRadius: '50%',
            borderColor: '#ddd',
            borderWidth: 2,
        },
    });
    const renderTimelinteItemText = (element, timelineIndex) => {
        var _a;
        const timeline = [];
        element.item &&
            Array.isArray(element.item) && ((_a = element.item) === null || _a === void 0 ? void 0 : _a.forEach((item, index) => {
            timeline.push(React.createElement(TextV, { type: item.type, ellipsizeMode: item.ellipsizeMode, numberOfLines: item.ellipsizeMode, color: item.color, key: ids.generate(), style: reactNative.StyleSheet.flatten([
                    styles.paragraphStyle,
                    item.style,
                    props.align === 'alternate' && {
                        textAlign: timelineIndex % 2 === 0 ? 'left' : 'right',
                    },
                    {
                        marginLeft: props.align === 'left'
                            ? 18
                            : props.align === 'alternate' && timelineIndex % 2 === 0
                                ? 18
                                : 0,
                        marginTop: index === 0 && (element.dotAvatar || element.dotIcon) ? 10 : 0,
                        marginRight: props.align === 'right'
                            ? 18
                            : props.align === 'alternate' && timelineIndex % 2 !== 0
                                ? 18
                                : 0,
                    },
                ]), align: item.align }, item.paragraph));
        }));
        return timeline;
    };
    const _getSingleTimeLine = (element, timelineIndex) => {
        if (props.renderItem && element) {
            return props.renderItem(element.key, element.item);
        }
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.paragraghContainer, props.textContainerStyle]) }, renderTimelinteItemText(element, timelineIndex)));
    };
    const _getIcons = icon => {
        return (React.createElement(Icon, { icon: icon.name, size: icon.size, style: reactNative.StyleSheet.flatten([styles.iconContainerStyle, icon.iconStyle, { border: '2px solid #ddd' }]) }));
    };
    const renderDots = (element) => {
        if (props.renderDot && element) {
            return props.renderDot(element.key, element);
        }
        if (element.dotIcon) {
            return _getIcons(element.dotIcon);
        }
        if (element.dotAvatar) {
            console.log("dotAvaatar");
            return (React.createElement(AvatarV, { source: element.dotAvatar, rounded: true, avatarStyle: reactNative.StyleSheet.flatten([styles.dotAvatarStyle]), containerStyle: reactNative.StyleSheet.flatten([styles.dotAvatarContainerStyle]) })
            // <img
            //   src={element.dotAvatar.source}
            //   style={StyleSheet.flatten([styles.dotAvatarContainerStyle])}
            // />
            );
        }
        return undefined;
    };
    const renderTimeInternal = (element, timelineIndex) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (props.renderTime && element) {
            return props.renderTime(element.key, element);
        }
        if (element.time) {
            return (React.createElement(TextV, { style: reactNative.StyleSheet.flatten([
                    styles.timelineText,
                    {
                        marginLeft: props.align === 'right'
                            ? 12
                            : props.align === 'alternate' && timelineIndex % 2 === 0
                                ? -12
                                : 12,
                        marginTop: 10,
                        marginRight: props.align === 'left'
                            ? 12
                            : props.align === 'alternate' && timelineIndex % 2 !== 0
                                ? -12
                                : 12,
                    },
                    props.timeContainerStyle
                ]), type: (_b = (_a = element.timeTextStyle) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : 'body', color: (_d = (_c = element.timeTextStyle) === null || _c === void 0 ? void 0 : _c.color) !== null && _d !== void 0 ? _d : 'rgba(0,0,0,0.6)', align: (_f = (_e = element.timeTextStyle) === null || _e === void 0 ? void 0 : _e.align) !== null && _f !== void 0 ? _f : 'auto', numberOfLines: (_h = (_g = element.timeTextStyle) === null || _g === void 0 ? void 0 : _g.numberOfLines) !== null && _h !== void 0 ? _h : 1, ellipsizeMode: (_k = (_j = element.timeTextStyle) === null || _j === void 0 ? void 0 : _j.ellipsizeMode) !== null && _k !== void 0 ? _k : 'tail' }, element.time));
        }
        return null;
    };
    const _getItems = () => {
        const timeLine = [];
        props.items &&
            props.items.forEach((element, index) => {
                timeLine.push(React.createElement(TimeLine.Item, { classPrefix: element.classPrefix, componentClass: element.componentClass, dot: renderDots(element), time: renderTimeInternal(element, index), style: reactNative.StyleSheet.flatten([element.style,]), key: ids.generate() }, _getSingleTimeLine(element, index)));
            });
        return timeLine;
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.containerStyle, props.containerStyle]) },
        React.createElement(TimeLine, { align: props.align, classPrefix: props.classPrefix, className: "custom-timeline", componentClass: props.componentClass, style: reactNative.StyleSheet.flatten([
                styles.timeLineStyle,
                props.timeLineStyle,
                props.width && { width: props.width },
                props.height && { height: props.height },
            ]) }, _getItems())));
};

exports.TimeLineV = TimeLineV;
