'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var InputNumber = _interopDefault(require('rsuite/lib/InputNumber'));
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));

function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = {
    inputNumberStyle: 'style',
    prefixComponent: 'prefix',
    suffixComponent: 'postfix',
};
const delete_keys = [
    'containerStyle',
    'inline',
    'onChange',
    'title',
    'titleStyle',
    'titleContainerStyle',
    'inputGroupStyle',
    'prefixComponent',
    'suffixComponent',
    'helpblock',
    'errorMessage',
];
const styles = reactNative.StyleSheet.create({
    linerStyle: {
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    suffixStyle: {},
    textInlineStyle: { marginRight: 10, marginTop: 10 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
});
const InputNumberV = (props) => {
    var _a;
    const [value, setValue] = React.useState(0);
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const handleOnChange = (value, event) => {
        props.onChange && props.onChange(value);
        setValue(value);
    };
    const _renderBody = () => {
        return (React__default.createElement(InputNumber, Object.assign({}, _widgetProps, { value: value, onChange: handleOnChange })));
    };
    const _renderTitle = () => {
        return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                props.titleContainerStyle,
                { flexWrap: 'wrap' },
            ]) },
            React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                    props.titleStyle,
                    props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
                ]) }, props.title)));
    };
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        props.title && _renderTitle(),
        React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                {
                    flexDirection: ((_a = props.helpblock) === null || _a === void 0 ? void 0 : _a.tooltip) && !props.errorMessage
                        ? 'row'
                        : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                },
            ]) },
            _renderBody(),
            props.helpblock && !props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: props.helpblock.tooltip, style: reactNative.StyleSheet.flatten([props.helpblock.style]) }, props.helpblock.message)),
            props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten(styles.errorMessageStyle) }, props.errorMessage)))));
};

exports.InputNumberV = InputNumberV;
