"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateNotNull = void 0;
exports.validateNotNull = function (item, ignoreProps) {
    var isValid = true;
    Object.keys(item).map(function (prop) {
        if (item[prop] == null) {
            if (ignoreProps && ignoreProps.indexOf(prop) > -1) {
                return;
            }
            isValid = false;
        }
    });
    return isValid;
};
