'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactFormRs = require('react-form-rs');
var reactLogoSpinnerExt = require('react-logo-spinner-ext');
var reactNative = require('react-native');
var expoLinearGradient = require('expo-linear-gradient');
var reactNativeElements = require('react-native-elements');
var reactViewportProvider = require('react-viewport-provider');
var Container = _interopDefault(require('rsuite/lib/Container'));
var Content = _interopDefault(require('rsuite/lib/Content'));
var Divider = _interopDefault(require('rsuite/lib/Divider'));

var ButtonType;
(function (ButtonType) {
    ButtonType["Solid"] = "solid";
    ButtonType["Clear"] = "clear";
    ButtonType["Outline"] = "outline";
})(ButtonType || (ButtonType = {}));
const ButtonV = (props) => {
    const { buttonStyle, containerStyle, disabled, disabledStyle, disabledTitleStyle, icon, iconContainerStyle, iconRight, linearGradientProps, loading, loadingProps, loadingStyle, onPress, raised, title, titleProps, titleStyle, type, TouchableComponent, ViewComponent, } = props;
    return (React.createElement(reactNativeElements.Button, { buttonStyle: buttonStyle && buttonStyle, containerStyle: containerStyle && containerStyle, disabled: disabled, disabledStyle: disabledStyle && disabledStyle, disabledTitleStyle: disabledTitleStyle && disabledTitleStyle, icon: icon && icon, iconContainerStyle: iconContainerStyle && iconContainerStyle, iconRight: iconRight && iconRight, linearGradientProps: linearGradientProps && linearGradientProps, loading: loading, loadingProps: loadingProps && loadingProps, loadingStyle: loadingStyle && loadingStyle, onPress: onPress && onPress, raised: raised && raised, title: title && title, titleProps: titleProps && titleProps, titleStyle: titleStyle && titleStyle, type: type && type, TouchableComponent: TouchableComponent, ViewComponent: linearGradientProps
            ? expoLinearGradient.LinearGradient
            : ViewComponent
                ? ViewComponent
                : reactNative.View }));
};

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

function DocumentIllustration(props) {
    var _a, _b, _c, _d, _e, _f, _g;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 758.697 683.675" },
        React.createElement("path", { fill: "#e6e6e6", d: "M948.765 379.171c0 118.6-76.18 219.4-182.28 256.14a272.758 272.758 0 01-132.97 11.26q-6.345-1.035-12.6-2.38-8.475-1.8-16.75-4.14c-113.91-32.06-197.4-136.72-197.4-260.88 0-149.67 121.33-271 271-271s271 121.33 271 271z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M508.028 462.814a2.043 2.043 0 00-.84 2.762l101.89 190.84a2.043 2.043 0 002.761.838l144.03-76.897a2.043 2.043 0 00.839-2.762L654.819 386.756a2.043 2.043 0 00-2.761-.84z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#fff", d: "M521.416 468.39l93.238 174.635 127.827-68.244-93.239-174.637zm-8.1 4.325l.018-.01-.02.01z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : "#6c63ff", d: "M553.645 487.59c-1.985 1.06-2.408 4.155-.944 6.898s4.272 4.114 6.258 3.054l93.62-49.983c1.985-1.06 2.408-4.155.943-6.899s-4.271-4.113-6.257-3.053zM565.18 509.194c-1.986 1.06-2.409 4.156-.944 6.899s4.272 4.114 6.258 3.053l93.619-49.983c1.986-1.06 2.408-4.155.944-6.898s-4.272-4.114-6.258-3.054zM576.715 530.799c-1.986 1.06-2.409 4.155-.944 6.898s4.271 4.114 6.257 3.054l93.62-49.983c1.985-1.06 2.408-4.156.943-6.899s-4.271-4.114-6.257-3.053zM588.25 552.403c-1.987 1.06-2.41 4.156-.945 6.899s4.272 4.114 6.258 3.053l93.619-49.983c1.986-1.06 2.409-4.155.944-6.898s-4.272-4.114-6.257-3.054zM599.784 574.008c-1.986 1.06-2.409 4.155-.944 6.898s4.271 4.114 6.257 3.054l93.62-49.983c1.985-1.06 2.408-4.156.944-6.899s-4.272-4.114-6.258-3.053z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M397.312 272.193L482.142 277.373 505.899 206.525 431.494 190.804 397.312 272.193z" }),
        React.createElement("circle", { cx: "458.491", cy: "220.351", r: "33.927", fill: "#ffb8b8" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M513.613 288.5L458.613 297.5 444.113 240 482.613 236.5 513.613 288.5z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M766.265 632.671l.22 2.64a272.758 272.758 0 01-132.97 11.26l-.29 4.69a16.05 16.05 0 01-21.79 13.94l9.48-21.01 11.99-26.59 7.86-17.43-2.83-60.3-.3-6.24-.37-7.96 5.12-.03 36.4-.22 34.69-.22 5.29-.03c33.17 40.8 60.54 73.96 47.5 107.5z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : "#6c63ff", d: "M718.265 524.671l-81 9-3-56c-9.506-24.244-3.231-45.884 16.497-65.303l4.011-15.876a23.504 23.504 0 0117.362-17.112c13.258-7.604 28.278-11.229 45.13-10.709a33.676 33.676 0 0121.324 33.987c-3.485 44.01-9.742 85.206-20.324 122.013z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M695.265 430.671l-.65 4.91-10.35 78.09-5.48 11.75-1.79 3.84-41.73 89.41-1.75 27.9-.29 4.69a16.03 16.03 0 11-30.73-7.28l1.67-3.93 9.1-21.38 24.67-78.8 2.03-6.5 2.42-7.73 6.88-21.97 10.96-67.56 1.04-6.44z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : "#6c63ff", d: "M701.265 437.671c-15.716-5.647-30.792-6.247-45 0l4.637-40.195a19.17 19.17 0 0118.152-19.794 19.17 19.17 0 0120.155 18.203z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M653.477 288.437s-17.542 6.402-17.993 29.677a42.02 42.02 0 001.553 11.589c1.138 4.277 2.05 13.559-5.6 25.93a239.877 239.877 0 00-13.532 24.915l19.753 1.906 3.266-32.745a162.534 162.534 0 0111.611-46.167l.128-.308s9.062 15.75 26.285 15.266l-6.324-5.978s21.572 8.123 33.302 9.754a7.429 7.429 0 014.474 12.354q-.104.113-.211.226c-7.174 7.59-21.451 25.229-12.458 38.518s1.715 17.189 1.715 17.189l13.325-7.763 6.047-3.864.138 4.921 31.882-4.591s8.577-1.473-10.465-21.867c0 0-5.102-7.984-3.167-15.05a35.346 35.346 0 00.877-16.56c-4.783-22.947-22.295-68.342-84.606-37.352z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("ellipse", { cx: "457.113", cy: "674.157", fill: "#e6e6e6", rx: "122", ry: "7" }),
        React.createElement("path", { fill: "#f2f2f2", d: "M221.394 430.704a1.001 1.001 0 00-.709 1.224l27.29 102.427a1.001 1.001 0 001.224.708l77.303-20.596a1.001 1.001 0 00.71-1.224l-27.29-102.426a1.001 1.001 0 00-1.225-.71z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#fff", d: "M240.39 447.62c-1.066.284-1.614 1.713-1.221 3.186s1.578 2.44 2.644 2.155l50.247-13.387c1.066-.284 1.613-1.714 1.22-3.186s-1.577-2.44-2.643-2.156zM243.479 459.215c-1.066.284-1.613 1.714-1.221 3.186s1.578 2.44 2.644 2.156l50.247-13.388c1.066-.284 1.614-1.713 1.221-3.185s-1.578-2.44-2.644-2.156zM246.568 470.81c-1.065.285-1.613 1.714-1.22 3.187s1.578 2.44 2.644 2.155l50.247-13.387c1.066-.284 1.613-1.714 1.22-3.186s-1.578-2.44-2.643-2.156zM249.658 482.406c-1.066.284-1.613 1.714-1.221 3.186s1.578 2.44 2.644 2.156l50.247-13.388c1.066-.284 1.613-1.713 1.221-3.185s-1.578-2.44-2.644-2.156zM252.747 494.002c-1.065.284-1.613 1.713-1.22 3.186s1.578 2.44 2.644 2.155l50.247-13.387c1.065-.284 1.613-1.714 1.22-3.186s-1.578-2.44-2.644-2.156zM255.837 505.597c-1.066.284-1.613 1.714-1.221 3.186s1.578 2.44 2.644 2.156l50.247-13.388c1.066-.284 1.613-1.713 1.221-3.185s-1.578-2.44-2.644-2.156z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#f2f2f2", d: "M418.948 791.597c0 .128.448.232 1 .232h106c.552 0 1-.104 1-.232V773.06c0-.127-.448-.231-1-.231h-106c-.552 0-1 .104-1 .231z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#fff", d: "M440.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463zM452.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463zM464.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463zM476.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463zM488.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463zM500.184 788.353c0 .256 1.24.464 2.764.464s2.764-.208 2.764-.464v-12.049c0-.255-1.24-.463-2.764-.463s-2.764.208-2.764.463z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M224.23 356.789a1.001 1.001 0 00-.411 1.353l49.923 93.507a1.001 1.001 0 001.353.411l70.572-37.678a1.001 1.001 0 00.411-1.353l-49.923-93.507a1.001 1.001 0 00-1.354-.411z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#fff", d: "M230.79 359.521l45.685 85.567 62.632-33.438-45.685-85.568zm-3.97 2.119l.01-.005-.01.005z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : "#6c63ff", d: "M246.582 368.928c-.973.52-1.18 2.036-.463 3.38s2.093 2.016 3.066 1.497l45.872-24.491c.973-.52 1.18-2.036.462-3.38s-2.093-2.016-3.066-1.496zM252.233 379.514c-.973.52-1.18 2.036-.462 3.38s2.093 2.016 3.066 1.496l45.871-24.49c.973-.52 1.18-2.036.463-3.38s-2.093-2.016-3.066-1.497zM257.885 390.1c-.973.52-1.18 2.036-.463 3.38s2.093 2.016 3.066 1.496l45.872-24.49c.973-.52 1.18-2.037.463-3.38s-2.093-2.016-3.066-1.497zM263.537 400.686c-.973.52-1.18 2.036-.463 3.38s2.093 2.015 3.066 1.496l45.872-24.49c.973-.52 1.18-2.037.462-3.38s-2.093-2.017-3.066-1.497zM269.188 411.271c-.973.52-1.18 2.036-.462 3.38s2.093 2.016 3.066 1.497l45.871-24.491c.973-.52 1.18-2.036.463-3.38s-2.093-2.016-3.066-1.496zM274.84 421.857c-.973.52-1.18 2.036-.462 3.38s2.093 2.016 3.066 1.496l45.871-24.49c.973-.52 1.18-2.036.463-3.38s-2.093-2.016-3.066-1.497z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M909.984 595.746a1.001 1.001 0 00-1.37.352l-53.927 91.257a1.001 1.001 0 00.352 1.37l68.873 40.7a1.001 1.001 0 001.37-.352l53.928-91.257a1.001 1.001 0 00-.353-1.37z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: "#fff", d: "M911.06 602.77l-49.348 83.509 61.124 36.122 49.35-83.51zm-3.873-2.289l.009.006-.01-.006z", transform: "translate(-220.651 -108.171)" }),
        React.createElement("path", { fill: (_g = props.fillColor) !== null && _g !== void 0 ? _g : "#6c63ff", d: "M911.233 621.151c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397zM905.128 631.482c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397zM899.023 641.813c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397zM892.918 652.144c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397zM886.813 662.475c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397zM880.708 672.806c-.95-.561-2.353.05-3.128 1.362s-.634 2.836.316 3.397l44.767 26.455c.95.561 2.353-.05 3.128-1.362s.634-2.836-.315-3.397z", transform: "translate(-220.651 -108.171)" })));
}

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
const ids = require('short-id');
const AVATAR_WIDTH = 40;
const AVATAR_HEIGHT = 40;
const PROFILE_ICON_COLOR = '#333333';
const TITLE_FONT_COLOR = '#565D61';
const TEXT_COLOR = '#414B47';
const HeaderV = (props) => {
    const { width, height, bp } = reactViewportProvider.useViewport();
    const styles = {
        avatarContainerStyle: {
            width: AVATAR_WIDTH,
            height: AVATAR_HEIGHT,
        },
        titleContainerStyle: {
            height: 35,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
        },
        titleStyle: {
            color: 'rgba(86,93,97,0.8)',
            fontWeight: 600,
            marginLeft: 10,
        },
        subtitleStyle: {
            color: TITLE_FONT_COLOR,
            marginLeft: 5,
        },
        avatarTitleContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: bp === 'xs' || bp === 'sm' ? 10 : 15,
        },
        loginButtonStyle: {
            width: 65,
            borderRadius: 5,
            alignSelf: 'center',
            borderColor: TEXT_COLOR,
        },
        signupButtonStyle: {
            width: 80,
            borderRadius: 5,
            marginLeft: 15,
            alignSelf: 'center',
        },
        signupButtonContainer: {
            width: 65,
            borderRadius: 5,
        },
        loginButtonContainerStyle: {
            alignItems: 'center',
        },
        signupButtonContainerStyle: {
            alignItems: 'center',
        },
        loginButtonTitleStyle: {
            color: TEXT_COLOR,
            fontSize: 15,
        },
        signupButtonTitleStyle: {
            color: '#FFFFFF',
            fontSize: 15,
        },
        profileContainer: {
            width: '80%',
        },
        profileItemContainer: {
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 30,
            marginBottom: 25,
        },
        profileIconStyle: {
            color: PROFILE_ICON_COLOR,
            alignSelf: 'center',
            width: 10,
        },
        profileTitleGroupContainer: {
            marginLeft: 30,
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        buttonContainer: {
            flexDirection: 'row',
            width: '100%',
        },
        containerStyle: {},
    };
    const renderBody = () => {
        const _body = [];
        if (props.title) {
            _body.push(renderHeader());
            _body.push(React.createElement(Divider, { key: ids.generate() }));
            //    if(props.renderBody) {
            //        _body.push(props.renderBody())
            //    }
        }
        return _body;
    };
    const renderHeader = () => {
        if (props.title) {
            return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'space-between', style: reactNative.StyleSheet.flatten([styles.titleContainerStyle]) },
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 18 },
                    React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.avatarTitleContainerStyle]) },
                        props.leftAvatar && renderAvatar(props.leftAvatar),
                        props.title && React.createElement(reactNative.View, null, renderTitle(props.title)))),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: bp === 'xs' || bp === 'sm' || bp === 'md' ? 6 : 4 }, props.loginButtonTitle && renderButton())));
        }
        return null;
    };
    const renderAvatar = (avatar) => {
        if (avatar) {
            return (React.createElement(AvatarV, { containerStyle: reactNative.StyleSheet.flatten([styles.avatarContainerStyle]), size: AVATAR_WIDTH, rounded: true, source: { uri: avatar } }));
        }
        return null;
    };
    const renderButton = () => {
        if (props.loginButtonTitle && props.signupButtonTitle) {
            return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.buttonContainer]) },
                React.createElement(ButtonV, { title: props.loginButtonTitle, buttonStyle: reactNative.StyleSheet.flatten([
                        styles.loginButtonStyle,
                        props.loginButtonStyle,
                        props.loginButtonColor && {
                            backgroundColor: props.loginButtonColor,
                        },
                    ]), type: ButtonType.Outline, titleStyle: reactNative.StyleSheet.flatten([
                        styles.loginButtonTitleStyle,
                        props.loginButtonTitleStyle,
                    ]), containerStyle: reactNative.StyleSheet.flatten([
                        styles.loginButtonContainerStyle,
                    ]), onPress: () => {
                        props.onLoginButtonPress &&
                            props.onLoginButtonPress(props.loginButtonId);
                    } }),
                bp !== 'xs' && bp !== 'sm' && (React.createElement(ButtonV, { containerStyle: reactNative.StyleSheet.flatten([
                        styles.signupButtonContainer,
                    ]), title: props.signupButtonTitle, buttonStyle: reactNative.StyleSheet.flatten([
                        styles.signupButtonStyle,
                        props.signupButtonStyle,
                        props.signupButtonColor && {
                            backgroundColor: props.signupButtonColor,
                            borderColor: props.signupButtonColor,
                        },
                    ]), type: ButtonType.Solid, titleStyle: reactNative.StyleSheet.flatten([
                        styles.signupButtonTitleStyle,
                        props.signupButtonTitleStyle,
                    ]), containerStyle: reactNative.StyleSheet.flatten([
                        styles.signupButtonContainerStyle,
                    ]), onPress: () => {
                        props.onSignupButtonPress &&
                            props.onSignupButtonPress(props.signupButtonId);
                    } }))));
        }
        return null;
    };
    const renderTitle = (title) => {
        const _title = [];
        if (title) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'title2', style: reactNative.StyleSheet.flatten([
                    styles.titleStyle,
                    props.titleStyle,
                    props.titleColor && { color: props.titleColor },
                ]) }, String(title)));
        }
        if (props.subtitle) {
            _title.push(React.createElement(TextV, { key: ids.generate(), type: 'callout', style: reactNative.StyleSheet.flatten([
                    styles.subtitleStyle,
                    props.subtitleStyle,
                    props.subtitleColor && { color: props.subtitleColor },
                ]) }, String(props.subtitle).toLowerCase()));
        }
        return _title;
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.containerStyle, { width: 1 * width }]) }, renderBody()));
};

function JumpIllustration(props) {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 596.672 728.998" },
        React.createElement("path", { fill: "#3f3d56", d: "M308.273 638.503a22.049 22.049 0 00-.45 31.026l135.38 138.258a22 22 0 0031.344.302L891.858 384.62a22.002 22.002 0 00-3.516-34.024L733.1 247.666a21.871 21.871 0 00-27.378 2.695z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#fff", d: "M867.395 368.248l-17.308-11.035a16.49 16.49 0 01-23.734 21.651l-112-77a16.48 16.48 0 014.358-29.301l-8.917-5.685a6 6 0 00-7.494.842L325.524 637.988a6 6 0 00.152 8.583l136.836 133.331a6 6 0 008.351-.116l397.542-402.23a6 6 0 00-1.01-9.308z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M130.873 470.728L246.456 579.706 521.379 308.086 381.853 217.271 130.873 470.728z" }),
        React.createElement("path", { fill: "#3f3d56", d: "M812.357 460.621a4.097 4.097 0 005.657 0l24.042-24.041a4.006 4.006 0 000-5.657 4.097 4.097 0 00-5.657 0l-24.042 24.041a4.006 4.006 0 000 5.657zM771.857 501.122a4.007 4.007 0 005.657 0l24.042-24.042a4 4 0 10-5.657-5.658l-24.042 24.042a4.006 4.006 0 000 5.658z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("circle", { cx: "133.543", cy: "564.271", r: "27", fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : '#6c63ff' }),
        React.createElement("ellipse", { cx: "286.043", cy: "434.771", fill: "#fff", rx: "74.5", ry: "40.5" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M491.671 169.159l-.89 8.087-.524 4.786-2.578 23.408s-1.966 3.65-5.48 8.767c-5.846 8.504-16.005 21.06-28.611 27.667-20.2 10.585-28.168-65.436-28.168-65.436s-4.364-29.474 6.36-30.556 7.035 11.614 7.85 32.502 14.822 38.557 14.822 38.557 14.908-20.685 21.459-34.564c3.412-7.23 7.55-10.516 10.725-11.996l.009-.004a13.145 13.145 0 013.299-1.073 7.81 7.81 0 011.727-.145z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : '#6c63ff', d: "M458.492 202.425l32.881 19.115 17.905-31.903-4.044-14.39a24.729 24.729 0 00-32.313 1.585z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M470.525 88.468c-19.034 7.869-27.385 31.372-18.653 52.496l11.094 26.838 8.255-3.412.668-11.92 2.916 10.438 53.055-21.932.607-10.836 2.65 9.49 5.974-2.47-8.71-21.073c-10.05-24.308-35.952-36.674-57.856-27.619z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#a0616a", d: "M489.448 225.933l3.346 24.31 52.64-23.994-8.448-17.733s-44.41 6.07-47.538 17.417z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M172.549 249.504L205.497 280.567 213.435 253.827 189.035 232.635 172.549 249.504z" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M273.458 372.003L293.508 415.101 314.498 406.425 293.493 363.721 273.458 372.003z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M475.775 287.44l22.556 27.54 77.414 146.735s14.935-1.705 22.503-10.42l-6.768-21.778-45.878-121.79s14.183-17.034 6.754-29.602-19.694-28.723-19.694-28.723zM593.696 488.925l-5.724 2.366s-9.12 23.878-14.056 28.152-27.807 32.72-2.772 25.723 35.173-25.711 35.173-25.711 9.604 1.615 18.521-6.54-1.54-17.237-1.54-17.237-12.54-15.247-12.682-15.027-2.316 11.173-16.92 8.274z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("circle", { cx: "479.317", cy: "128.465", r: "23.745", fill: "#ffb8b8", transform: "rotate(-22.459 113.166 845.402)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M523.587 143.677l-6.615 12.588-.89 1.686 3.78 11.843-37.21 15.381s3.258-6.496 6.09-12.995c.052-.145.11-.303.18-.455.384-.884.72-1.693 1.022-2.422.158-.4.315-.778.45-1.124.222-.572.442-1.121.662-1.67a.036.036 0 00.002-.023 40.294 40.294 0 001.414-4.36l.006-.014a12 12 0 00.249-1.209c.89-5.954-9.732-12.733-9.732-12.733l16.65-19.172c5.01 10.039 16.615 13.33 21.6 14.307 1.456.28 2.342.372 2.342.372z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M641.365 282.663c-12.733 9.732-25.519-18.496-25.519-18.496l-35.68-30.99a97.753 97.753 0 01-19.25-22.728l-16.159-26.37-.69 2.195.004.01-1.947 2.234-24.762-17.69-6.94-4.952-3.155-7.632 11.55-12.595 5.724-2.366s.117-.07.328-.18c2.519-1.366 19.18-9.594 26.972 6.768 3.06 6.421 5.277 11.872 6.863 16.213a136.33 136.33 0 0017.845 33.744c2.723 3.726 5.313 6.87 7.212 8.342 6.577 5.101 41.626 52.053 41.626 52.053s28.712 12.707 15.978 22.44z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#d0cde1", d: "M492.472 162.125c.006.12-.092 1.155-2.077 6.054a49.827 49.827 0 002.077-6.054z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : '#6c63ff', d: "M544.477 184.753l-.406 1.53-.294 31.489s-12.476 22.572-26.621 26.186c-8.042 1.807-23.12-6.114-24.468 2.018l-5.607-25.767-4.883-6.002c-.57-.703-1.213-1.476-1.923-2.355-6.806-8.358-.65-23.191-.65-23.191l7.01-18.28.01-.004a13.145 13.145 0 013.299-1.073c-.303.728-.638 1.537-1.021 2.421-.071.152-.129.31-.181.455-2.112 5.24.216 5.496 2.039 5.066a9.093 9.093 0 001.743-.62c4.215-1.072 11.482-8.165 16.667-13.727 3.53-3.782 6.103-6.856 6.103-6.856l5.174-11.076.776-1.662c1.457.28 2.343.372 2.343.372l.954-.394s.117-.07.328-.18l-3.012 5.019a17.476 17.476 0 00-.711 16.672z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M503.721 233.438l-11.697 11.213s-87.845 2.009-89.434 30.593 73.595 64.531 73.595 64.531l18.559-19.96-21.373-24.678s74.661 23.874 86.91-11.351-18.073-65.339-18.073-65.339-18.974-5.363-38.487 14.991zM506.64 353.996l-5.826 10.228s20.253 27.375 19.363 33.328-1.093 21.677 9.007 16.385 14.844-26.245 13.432-32.363-1.833-26.053-1.833-26.053.406-31.447-8.575-28.851-22.921 7.61-22.921 7.61zM484.679 94.954a19.559 19.559 0 00-17.27-1.382l-.677.28c-13.047 5.393-18.743 21.57-12.723 36.132l4.372-1.807-1.516-5.665 3.257 4.946 25.927-10.718.293-5.444 1.336 4.77 5.1-2.108q10.9 16.254 4.4 39.702l8.688-3.592.586-10.888 2.673 9.54 16.562-6.846-5.386-22.26c-6.475-15.661-21.064-25.18-35.622-24.66z", transform: "translate(-301.664 -85.501)" }),
        React.createElement("path", { fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : '#6c63ff', d: "M582.74 177.375L532.61 198.1l-16.08-38.895 6.592-15.882a28.45 28.45 0 0136.693 6.238z", transform: "translate(-301.664 -85.501)" })));
}

function NetworkIllustration(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 965.825 729.214" },
        React.createElement("path", { fill: "#3f3d56", d: "M562.48 240.958H324.225a4.417 4.417 0 01-4.412-4.412v-59.102a4.417 4.417 0 014.412-4.412H562.48a4.417 4.417 0 014.412 4.412v59.102a4.417 4.417 0 01-4.412 4.412zm-238.256-66.161a2.65 2.65 0 00-2.648 2.647v59.102a2.65 2.65 0 002.648 2.647H562.48a2.65 2.65 0 002.647-2.647v-59.102a2.65 2.65 0 00-2.647-2.647z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "244.157", cy: "119.334", r: "17.973", fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : '#6c63ff' }),
        React.createElement("path", { fill: "#e6e6e6", d: "M402.753 192.745a2.995 2.995 0 000 5.991h141.215a2.995 2.995 0 000-5.99zM402.753 210.718a2.995 2.995 0 100 5.991h60.765a2.995 2.995 0 100-5.99z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M459.044 451.627H353.152a4.417 4.417 0 01-4.412-4.412V336.933a4.417 4.417 0 014.412-4.412h105.892a4.417 4.417 0 014.412 4.412v110.282a4.417 4.417 0 01-4.412 4.412zM353.152 334.285a2.65 2.65 0 00-2.648 2.648v110.282a2.65 2.65 0 002.648 2.647h105.892a2.65 2.65 0 002.647-2.647V336.933a2.65 2.65 0 00-2.647-2.648z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M375.715 355.263a2.995 2.995 0 000 5.99h60.765a2.995 2.995 0 000-5.99zM375.715 372.911a2.995 2.995 0 100 5.991h60.765a2.995 2.995 0 100-5.99z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : '#6c63ff', d: "M406.098 428.885a18.855 18.855 0 1118.855-18.855 18.876 18.876 0 01-18.855 18.855z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M632.513 85.393h105.892a4.417 4.417 0 014.413 4.412v110.283a4.417 4.417 0 01-4.413 4.412H632.513a4.417 4.417 0 01-4.412-4.412V89.805a4.417 4.417 0 014.412-4.412zm105.892 117.342a2.65 2.65 0 002.648-2.647V89.805a2.65 2.65 0 00-2.648-2.647H632.513a2.65 2.65 0 00-2.647 2.647v110.283a2.65 2.65 0 002.647 2.647z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M715.842 181.758a2.995 2.995 0 100-5.991h-60.765a2.995 2.995 0 100 5.99zM715.842 164.109a2.995 2.995 0 100-5.99h-60.765a2.995 2.995 0 100 5.99z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : '#6c63ff', d: "M685.459 108.135a18.855 18.855 0 11-18.855 18.855 18.876 18.876 0 0118.855-18.855z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M321.172 313.55a150.953 150.953 0 0163.905-28.888 148.77 148.77 0 0170.528 3.624 150.459 150.459 0 0132.912 14.26c10.56 6.173 20.1 13.772 29.28 21.817 9.3 8.15 18.293 16.663 28.001 24.336a156.871 156.871 0 0097.604 34.335q4.397.007 8.788-.242c1.587-.087 1.597-2.568 0-2.48a154.343 154.343 0 01-100.896-30.488c-10.062-7.524-19.249-16.126-28.628-24.463-9.093-8.082-18.43-15.944-28.746-22.437a151.858 151.858 0 00-167.662 3.822 157.962 157.962 0 00-6.839 5.051c-1.258.98.508 2.723 1.753 1.754z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "202.769", cy: "227.257", r: "12.886", fill: "#3f3d56" }),
        React.createElement("circle", { cx: "682.146", cy: "398.345", r: "12.886", fill: "#3f3d56" }),
        React.createElement("path", { fill: "#3f3d56", d: "M866.74 410.334H656.481a4.417 4.417 0 01-4.412-4.412v-109.4a4.417 4.417 0 014.412-4.412h210.257a4.417 4.417 0 014.412 4.412v109.4a4.417 4.417 0 01-4.412 4.412zm-210.258-116.46a2.65 2.65 0 00-2.647 2.648v109.4a2.65 2.65 0 002.647 2.647h210.257a2.65 2.65 0 002.647-2.647v-109.4a2.65 2.65 0 00-2.647-2.647z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "574.65", cy: "245.471", r: "17.973", fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : '#6c63ff' }),
        React.createElement("path", { fill: "#e6e6e6", d: "M733.246 318.883a2.995 2.995 0 100 5.99h113.215a2.995 2.995 0 100-5.99zM733.246 336.856a2.995 2.995 0 100 5.99h60.765a2.995 2.995 0 100-5.99zM677.952 365.589c-2.279 0-4.133 1.344-4.133 2.995s1.854 2.996 4.133 2.996h166.833c2.279 0 4.133-1.344 4.133-2.996s-1.854-2.995-4.133-2.995zM677.952 383.562c-2.279 0-4.133 1.344-4.133 2.995s1.854 2.996 4.133 2.996h166.833c2.279 0 4.133-1.344 4.133-2.996s-1.854-2.995-4.133-2.995z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M639.58 495.734c11.862-5.145 25.106-.66 36.286 4.084 5.758 2.442 11.455 5.05 17.414 6.978a99.26 99.26 0 0015.353 3.639 108.498 108.498 0 0031.54.193 110.124 110.124 0 0030.437-8.85 102.92 102.92 0 0026.343-17.224q1.497-1.352 2.936-2.766c1.142-1.119-.612-2.872-1.753-1.754a99.998 99.998 0 01-25.698 18.141 107.65 107.65 0 01-29.857 9.592 105.438 105.438 0 01-31.462.543 99.05 99.05 0 01-15.405-3.35 162.963 162.963 0 01-17.002-6.602c-11.285-4.847-23.823-9.592-36.223-6.25a30.212 30.212 0 00-4.16 1.485c-1.46.634-.199 2.77 1.251 2.141z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: (_g = props.fillColor) !== null && _g !== void 0 ? _g : '#6c63ff', d: "M634.998 526.146H535.66l23.378-40.491a4.774 4.774 0 018.268 0l9.986 17.297 16.98-29.411a5.977 5.977 0 0110.353 0z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M635.091 526.135H529.2a4.417 4.417 0 01-4.412-4.412V462.62a4.417 4.417 0 014.412-4.412h105.892a4.417 4.417 0 014.412 4.412v59.102a4.417 4.417 0 01-4.412 4.412zM529.2 459.974a2.65 2.65 0 00-2.647 2.647v59.102a2.65 2.65 0 002.647 2.647h105.892a2.65 2.65 0 002.648-2.647V462.62a2.65 2.65 0 00-2.648-2.647z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M842.785 474.12l-18.875 9.584c-6.737 3.421-13.238 2.837-15.728-4.297a12.48 12.48 0 019.493-16.382l17.939-3.349 76.83-25.49 46.54-33 17.06 27.18-53.776 29.018z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M813.808 706.872L799.366 704.465 800.971 677.187 816.215 677.187 813.808 706.872z" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M953.968 680.229L939.992 684.589 929.131 659.516 942.738 652.646 953.968 680.229z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M1063.272 741.72l-17.65 7.221-84.24-110.716-26.476 129.971-17.65-3.21c-11.322-112.051-20.555-226.343-9.226-280.399l65.386-2.808 29.685 94.67 14.441 64.183zM930.1 814.326a12.672 12.672 0 01-7.855-.835l-8.579-3.86c-7.193-.37-12.09 1.063-16.096-4.164a10.496 10.496 0 01-1.225-10.581 10.569 10.569 0 018.63-6.28l11.784-7.425.618 5.566c5.386 3.05 11.78 1.733 18.554-1.237l3.699 12.944a12.672 12.672 0 01-9.53 15.872zM1076.774 787.774a12.672 12.672 0 01-7.7 1.761l-9.368-.865c-6.924 1.986-11.09 4.932-16.576 1.29a10.496 10.496 0 01-4.595-9.61 10.569 10.569 0 016.124-8.743l8.733-10.85 2.393 5.065c6.084 1.137 11.703-2.186 17.147-7.195l7.7 11.041a12.672 12.672 0 01-3.858 18.106z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "832.26", cy: "253.579", r: "28.882", fill: "#ffb8b8" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M867.561 314.553L829.853 306.53 825.04 275.241 851.515 269.625 867.561 314.553z" }),
        React.createElement("path", { fill: (_h = props.fillColor) !== null && _h !== void 0 ? _h : '#6c63ff', d: "M974.218 485.79h-67.392l15.19-38.137c-1.103-13.123 4.459-22.775 12.419-31.178l10.099-25.354c16.317-10.74 28.619-9.937 41.719 4.011 15.531 21.99 4.78 55.048-12.035 90.659z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M943.732 566.822l-1.917 21.082c-.684 7.525-4.636 12.72-12.002 11.037a12.48 12.48 0 01-8.828-16.75l6.7-16.974 19.256-78.624-3.21-56.962h32.092l-4.011 60.973z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: (_j = props.fillColor) !== null && _j !== void 0 ? _j : '#6c63ff', d: "M978.23 431.235h-36.905l2.587-24.075a16.7 16.7 0 0115.853-15.999 16.7 16.7 0 0117.522 16.026z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "868.006", cy: "213.025", r: "10.856", fill: "#2f2e41" }),
        React.createElement("path", { fill: "#2f2e41", d: "M973.907 295.76a10.848 10.848 0 0110.851-10.146c-.224-.028-.446-.063-.675-.078a10.856 10.856 0 00-1.393 21.668c.229.015.454.009.68.01a10.848 10.848 0 01-9.463-11.453z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M973.846 300.396c-9.682-8.031-37.452 2.177-39.244 7.577-13.093 1.784-23.005 9.423-23.005 23.005 7.784 3.189 16.636 2.796 26.206 0 15.255 12.432 16.301 28.06 7.024 46.01l11.413.23 14.148-20.678.677 20.974 26.163-.525v-30.057a62.841 62.841 0 00-6.71-28.79c-4.077-7.921-10.404-12.546-16.672-17.746z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("ellipse", { cx: "832.396", cy: "260.84", fill: "#ffb8b8", rx: "5.191", ry: "7.268" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M296.282 355.456s3.125-54.09 19.348-41.8-7.085 46.816-7.085 46.816zM235.923 515.9s36.038 40.457 15.876 43.238-28.094-38.113-28.094-38.113z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#575a89", d: "M188.897 324.395a23.734 23.734 0 0117.463 19.65l11.463 81.592 16.07 65.084s8.035 7.231 4.821 11.249 4.018 20.89 4.018 20.89l-20.088 6.429s-2.41-16.874-6.428-17.677c-3.53-.706-43.054-106.29-52.581-131.867-1.314-3.527-2.057-5.533-2.057-5.533s1.607-53.834 27.32-49.817z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M192.566 749.885l12.339 21.865c9.554 20.4 1.064 24.105-22.364 13.993l-5.34-25.818z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M198.792 778.945s5.857-9.536 7.975-7.94 10.857 8.513 10.857 8.513l11.381 10.901s38.454 4.065 14.02 17.539-37.938 2.302-37.938 2.302-34.222 1.78-33.67-7.769 11.589-24.653 11.589-24.653 13.91 9.326 15.786 1.107zM137.815 502.732l11.567 139.963s.386 83.67 12.724 99.864 12.724 24.291 12.724 24.291l25.448-13.11s-13.11-21.844-10.41-25.188a18.638 18.638 0 003.47-7.2l-6.94-86.368 13.109-150.374z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M52.344 664.492L41.548 693.024 17.064 682.788 29.981 661.407 52.344 664.492z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M151.42 768.807s9.183-5.74 10.331-3.444 5.74 12.053 5.74 12.053l5.165 14.348s31.566 20.087 4.591 21.235-33.861-14.348-33.861-14.348-30.418-13.2-25.827-21.235 20.662-16.368 20.662-16.368 8.034 14.072 13.2 7.759zM235.75 503.117l6.17 128.782s-56.294 116.443-62.463 119.528a15.488 15.488 0 00-7.712 9.254l-33.93-8.483 15.423-24.677 33.93-82.512s5.398-2.314 6.94-10.025 4.627-7.712-3.084-9.254-7.712-9.254-7.712-9.254l-25.447-117.214z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("circle", { cx: "207.989", cy: "270.231", r: "30.846", fill: "#ffb9b9", transform: "rotate(-80.783 99.262 296.344)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M180.613 272.159s-2.313 39.328-5.398 43.184 32.389 3.084 32.389 3.084-2.314-33.159 0-36.243-26.99-10.025-26.99-10.025z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#575a89", d: "M238.061 511.601s-87.14-7.71-103.33 0c0 0 5.96-22.11 4.42-24.42-1.12-1.69 1.97-64.67 1-101-.55-20.5-5.28-40.52 8.47-55.74 4.68-5.17 9.9-9.51 15.41-11.63 0 0-1.54-14.65 6.17-15.42 0 0 29.31-3.09 40.1 6.17 3.45 2.94 5.62 11.9 5.85 19.62-5.34 26.26-3.45 55.4 1.45 85.63.3 1.92.63 3.85.96 5.78 5.2 29.98 13.06 60.89 19.5 91.01z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M191.875 279.32c2.608-.156 5.156.726 7.746 1.07 9.268 1.23 18.91-5.495 20.957-14.618a11.216 11.216 0 011.071-3.492c1.427-2.33 4.675-2.773 7.353-2.238s5.217 1.789 7.944 1.932c4.211.221 8.254-2.371 10.498-5.941a20.148 20.148 0 002.577-12.169l-2.019 2.119a10.254 10.254 0 01-.914-5.573 6.467 6.467 0 00-6.125 1.567c-1.78.19-.437-3.301-1.76-4.508a3.06 3.06 0 00-2.152-.402c-3.786.036-6.85-2.86-9.834-5.192a41.79 41.79 0 00-17.65-8.05c-4.275-.835-8.822-.969-12.878.624a27.51 27.51 0 00-8.697 6.169c-6.348 6.105-11.97 13.278-14.612 21.68a36.497 36.497 0 00-.174 21.163c1.064 3.592 4.271 15.894 8.967 16.18 5.9.36 2.358-9.883 9.702-10.321z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { fill: "#575a89", d: "M204.643 328.181l11.05 1.184s10.849 21.046 10.061 25.436-2.503 1.725 2.38 8.749 18.737 25.178 20.453 27.843 48.488-33.022 45.58-43.714c0 0 14.773 10.598 21.101 12.008 0 0-48.203 63.218-54.92 71.309s-12.124 6.428-21.355 1.631-55.933-52.644-55.933-52.644z", transform: "translate(-117.087 -85.393)" }),
        React.createElement("path", { d: "M218.561 420.59l-38.41-38.41 9.72-3.17 27.73 35.8c.3 1.921.63 3.851.96 5.78z", opacity: "0.1", transform: "translate(-117.087 -85.393)", style: { isolation: 'isolate' } })));
}

function NotificationIllustration(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 856.946 596.18" },
        React.createElement("path", { fill: "#3f3d56", d: "M426.41 552.324a22.049 22.049 0 00-.45 31.025l139.38 144.258a22 22 0 0031.343.303l425.312-423.47a22.002 22.002 0 00-3.517-34.024l-167.24-108.929a21.871 21.871 0 00-27.379 2.695z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M1024.911 276.85a22.143 22.143 0 01-2.916 3.59L597.683 711.91a22 22 0 01-31.343-.303L423.96 561.35a21.907 21.907 0 01-2.603-3.28c-5.46 8.4-2.636 17.79 4.602 25.28l139.38 144.258a22 22 0 0031.344.303l425.312-423.47a22.008 22.008 0 002.916-27.59z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#fff", d: "M985.532 282.068l-17.308-11.035a16.49 16.49 0 01-23.734 21.652l-112-77a16.48 16.48 0 014.357-29.301l-8.917-5.686a6 6 0 00-7.493.842L443.66 551.81a6 6 0 00.152 8.582l136.835 133.33a6 6 0 008.352-.115l397.542-402.23a6 6 0 00-1.01-9.309z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M379.147 318.14L494.73 427.118 769.652 155.498 630.127 64.683 379.147 318.14z" }),
        React.createElement("path", { fill: "#3f3d56", d: "M930.494 374.442a4.097 4.097 0 005.657 0l24.042-24.042a4.006 4.006 0 000-5.657 4.097 4.097 0 00-5.657 0l-24.042 24.042a4.006 4.006 0 000 5.657zM889.994 414.942a4.007 4.007 0 005.657 0l24.042-24.042a4 4 0 00-5.657-5.657l-24.042 24.042a4.006 4.006 0 000 5.657z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("circle", { cx: "381.816", cy: "411.683", r: "27", fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : '#6c63ff' }),
        React.createElement("path", { fill: "#3f3d56", d: "M643.194 584.088L69.113 584.088 69.113 581.907 643.589 581.907 643.194 584.088z" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M293.407 178.372H578.817V234.722H293.407z" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M293.407 89.333H578.817V145.683H293.407z" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M293.407 0H578.817V56.35H293.407z" }),
        React.createElement("path", { fill: "#fff", d: "M471.018 202.175H744.26v-44.18H471.02zM471.018 291.704H744.26v-44.18H471.02zM471.018 380.547H744.26v-44.18H471.02z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("circle", { cx: "323.411", cy: "27.809", r: "16.832", fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : '#6c63ff' }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : '#6c63ff', d: "M363.661 21.223H529.784V24.881999999999998H363.661z" }),
        React.createElement("path", { fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : '#6c63ff', d: "M363.661 30.736H529.784V34.395H363.661z" }),
        React.createElement("circle", { cx: "323.411", cy: "117.873", r: "16.832", fill: "#ccc" }),
        React.createElement("path", { fill: "#ccc", d: "M363.661 111.287H529.784V114.94600000000001H363.661z" }),
        React.createElement("path", { fill: "#ccc", d: "M363.661 120.801H529.784V124.46000000000001H363.661z" }),
        React.createElement("circle", { cx: "323.411", cy: "206.913", r: "16.832", fill: "#ccc" }),
        React.createElement("path", { fill: "#ccc", d: "M363.661 200.327H529.784V203.986H363.661z" }),
        React.createElement("path", { fill: "#ccc", d: "M363.661 209.84H529.784V213.499H363.661z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M184.33 742.634l7.362.865c5.816 3.506 12.642 4.826 20.203 4.557a6.058 6.058 0 005.132-3.145 6.004 6.004 0 00-3.917-8.707l-5.595-1.303-13.5-13.204-8.144-14.74-11.83 1.357-2.463 18.722a14.492 14.492 0 0012.752 15.598zM299.004 733.162l7.216 1.698c5.379 4.146 12.01 6.236 19.552 6.83a6.058 6.058 0 005.456-2.54 6.004 6.004 0 00-2.899-9.097l-5.41-1.932-11.907-14.656-6.412-15.571h-11.908l-4.58 18.32a14.492 14.492 0 0010.892 16.948z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M304.92 698.93L291.454 701l-21.235-107.208-36.772-62.668-44.54 182.306-16.574 3.108c1.859-102.664-7.212-231.912 13.466-247.564l90.117 10.359 39.362 105.654z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("circle", { cx: "68.135", cy: "126.473", r: "31.075", fill: "#ffb8b8" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M24.63 174.121L61.92 180.336 71.243 147.189 44.311 138.903 24.63 174.121z" }),
        React.createElement("path", { fill: (_g = props.fillColor) !== null && _g !== void 0 ? _g : '#6c63ff', d: "M180.62 473.119l99.44 9.322c-7.972-27.72-15.276-55.343-9.323-82.866 7.56-24.572.263-39.184-19.68-50.756L237.59 318.78l-36.254-4.143a78.256 78.256 0 00-11.108 51.75c5.166 39.178 3.771 75.533-9.609 106.732z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M227.443 243.116c10.613-.733 22.883-.935 24.42 1.86 10.99-.514 20.56 3.32 24.976 12.241a5.88 5.88 0 01-3.673 8.233c-5.575 1.608-14.073 3.596-20.273 2.961-12.877 15.14-7.46 42.751 5.04 58.882l-11.295 2.315-21.432-29.846 6.763 32.853-26.082 4.266-9.096-41.751a63.465 63.465 0 011.396-29.822 23.967 23.967 0 011.092-2.869 33.3 33.3 0 0128.164-19.323z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: "#ffb8b8", d: "M244.842 504.194l-.688 24.048a13.112 13.112 0 0011.802 13.42 13.112 13.112 0 0013.962-16.467l-5.396-19.966-15.537-84.938 1.036-49.72-33.147-1.035 6.215 55.934z", transform: "translate(-171.527 -151.91)" }),
        React.createElement("path", { fill: (_h = props.fillColor) !== null && _h !== void 0 ? _h : '#6c63ff', d: "M214.803 374.715a52.381 52.381 0 0137.29 0l-2.072-41.433a16.573 16.573 0 00-16.574-16.574 16.573 16.573 0 00-16.573 16.574z", transform: "translate(-171.527 -151.91)" })));
}

function ShareIllustration(props) {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 847.936 568.361" },
        React.createElement("path", { fill: "#e6e6e6", d: "M1012.59 734.18H639.937a11.391 11.391 0 01-11.378-11.378V459.378A11.391 11.391 0 01639.937 448h372.653a11.391 11.391 0 0111.378 11.378v263.424a11.391 11.391 0 01-11.378 11.378z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#fff", d: "M997.076 463.513h-341.62a11.398 11.398 0 00-11.384 11.383v232.388a11.398 11.398 0 0011.384 11.383h341.62a11.39 11.39 0 0011.374-11.383V474.896a11.39 11.39 0 00-11.374-11.383z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("circle", { cx: "638.059", cy: "397.968", r: "45.875", fill: "#a0616a" }),
        React.createElement("path", { fill: "#a0616a", d: "M867.339 659.634l-81.92 8.192c7.572-34.087 9.407-65.934 0-93.389h57.344c-3.115 22.878 8.529 52.962 24.576 85.197z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : "#6c63ff", d: "M923.874 659.888c-1.022 19.976-2.206 39.627-3.666 58.78H728.666q-3.4-18.969-8.267-38.177a17.522 17.522 0 019.83-20.354l6.112-2.722 53.99-23.994.634.137 22.303 4.773 39.327-9.829.687.137 55.13 11.28a19.644 19.644 0 014.705 1.615 19.382 19.382 0 0110.757 18.354z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : "#6c63ff", d: "M953.792 718.667h-41.387l-7.383-78.696a45.04 45.04 0 0139.601 35.498zM741.062 718.667h-39.687l1.425-13.916a53.247 53.247 0 0133.464-48.392l.077 1.056z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M777.669 537.649c20.677-11.83 41.383-4.272 62.125 28.008l4.24 34.385c31.13-29.491 29.556-69.738-5.354-86.107-3.432-1.61-6.758-3.44-10.493-2.793-16.145-8.646-35.449-2.152-43.538 11.697-6.45 1.117-15.696 13.93-15.363 20.468z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("ellipse", { cx: "661.816", cy: "400.426", fill: "#a0616a", rx: "5.734", ry: "7.373" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M664.995 530.195H190.519a14.504 14.504 0 01-14.487-14.487V180.307a14.504 14.504 0 0114.487-14.487h474.476a14.504 14.504 0 0114.487 14.487v335.401a14.504 14.504 0 01-14.487 14.487z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#fff", d: "M645.242 185.57H210.278a14.512 14.512 0 00-14.493 14.494V495.95a14.512 14.512 0 0014.493 14.493h434.964a14.503 14.503 0 0014.482-14.493V200.064a14.503 14.503 0 00-14.482-14.493z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M353.705 230.274L219.549 260.975 162.866 154.068 277.58 109.282 353.705 230.274z" }),
        React.createElement("circle", { cx: "240.384", cy: "171.692", r: "48.594", fill: "#ffb9b9" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M410.771 497.639l117.96-17.03c-17.608-31.871-35.763-60.919-60.163-57.671-21.706-15.192-22.958-39.615-17.468-67.08l-59.043 15.784c5.195 16.93 3.186 31.796-10.651 43.274 19.333 27.216 31.845 54.688 29.365 82.723z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : "#6c63ff", d: "M553.569 510.443H366.91q-3.968-5.328-8.439-10.307c-.196-.23-.404-.47-.612-.7l5.017-70.927.503-7.17a9.547 9.547 0 016.209-8.296h.01a6.196 6.196 0 01.864-.295l15.477-4.132 1.542-.415.218.262 8.69 10.078 5.804 6.744 25.664 29.785a26.766 26.766 0 0018.385 9.225l9.499.667c16.81 1.191 28.714-12.417 28.714-26.856a25.87 25.87 0 00-4.274-14.264 26.404 26.404 0 00-2.317-3.082l10.8 1.738L504.14 425l17.445 41.01c17.533 13.904 28.605 28.66 31.983 44.432z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M152.415 283.359L175.882 325.791 162.645 338.109 155.639 344.624 94.822 344.624 131.275 305.843 152.415 283.359z" }),
        React.createElement("path", { fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : "#6c63ff", d: "M566.346 510.443h-57.253l-6.066-26.112-12.931-55.669-1.432-6.165-.033-.142 13.543.82a41.754 41.754 0 0135.447 24.342zM340.259 506.304l56.314-19.065-19.855-67.552a9.586 9.586 0 00-10.41-6.806 9.586 9.586 0 00-5.15 2.339l-57.527 51.037c11.006 18.118 22.723 18.572 36.628 40.047z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M448.699 258.619s29.719 5.59 36.591 42.654a68.044 68.044 0 01.582 18.926c-.686 7.135.31 22.204 15.801 39.947a388.439 388.439 0 0128.207 36.226l-31.053 8.267-13.875-51.45a263.197 263.197 0 00-30.755-70.688l-.285-.457s-10.314 27.558-37.958 31.336l8.523-11.222s-32.316 18.681-50.625 24.388a12.03 12.03 0 00-3.881 20.918q.194.153.396.307c13.467 10.228 40.94 34.634 30.086 58.243s1.804 27.914 1.804 27.914l-23.34-8.879-10.682-4.574 1.079 7.898-52.149 1.092s-14.091-.085 10.94-37.7c0 0 6.04-14.105 1.081-24.88-3.802-8.264-6.158-17.14-5.78-26.228 1.576-37.923 17.552-115.075 125.293-82.038z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M847.816 399.638a1 1 0 01-.979-.796c-4.758-22.886-21.223-43.918-46.36-59.221-25.469-15.505-58.17-24.044-92.076-24.044a1 1 0 010-2c34.268 0 67.338 8.643 93.116 24.336 25.616 15.594 42.406 37.088 47.28 60.522a1.001 1.001 0 01-.981 1.203z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M661.281 231.145L673.255 247.094 681.082 228.751 661.281 231.145z" }),
        React.createElement("path", { fill: "#3f3d56", d: "M591.5 652.632c-34.269 0-67.338-8.643-93.116-24.336-25.617-15.595-42.407-37.088-47.28-60.523a1 1 0 111.959-.406c4.758 22.886 21.222 43.917 46.361 59.22 25.469 15.506 58.169 24.045 92.076 24.045a1 1 0 010 2z", transform: "translate(-176.032 -165.82)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M286.555 403.424L274.581 387.475 266.754 405.818 286.555 403.424z" })));
}

function StoreIllustration(props) {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 747.211 570.007" },
        React.createElement("path", { fill: "#e6e6e6", d: "M265.366 212.823a4.776 4.776 0 00-4.782 4.76L259.41 728.02a4.776 4.776 0 004.759 4.782l411.213.946a4.776 4.776 0 004.781-4.76l1.175-510.438a4.776 4.776 0 00-4.76-4.781z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#fff", d: "M280.596 228.124a4.776 4.776 0 00-4.782 4.76L274.71 712.79a4.776 4.776 0 004.76 4.781l380.682.876a4.776 4.776 0 004.781-4.759l1.104-479.908a4.776 4.776 0 00-4.76-4.781z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M610.71 436.519l-280.502-.646a7.156 7.156 0 11.033-14.311l280.502.645a7.156 7.156 0 01-.033 14.312zM613.426 303.19l-186.094-.428c-2.622-.006-4.74-3.215-4.731-7.167s2.142-7.15 4.763-7.145l186.094.429c2.623.006 4.74 3.214 4.731 7.166s-2.141 7.151-4.763 7.145zM613.331 344.216l-186.094-.428c-2.622-.006-4.74-3.215-4.73-7.167s2.141-7.15 4.763-7.145l186.094.429c2.622.006 4.74 3.214 4.73 7.166s-2.14 7.15-4.763 7.145zM610.604 482.792l-280.503-.645a7.156 7.156 0 01.033-14.312l280.503.646a7.156 7.156 0 01-.033 14.311zM610.497 529.065l-280.502-.645a7.156 7.156 0 11.033-14.311l280.502.645a7.156 7.156 0 11-.033 14.311zM610.39 575.339l-280.502-.646a7.156 7.156 0 11.033-14.311l280.503.645a7.156 7.156 0 01-.033 14.312zM610.284 621.612l-280.502-.645a7.156 7.156 0 01.033-14.312l280.502.646a7.156 7.156 0 01-.033 14.311zM610.178 667.886l-280.503-.646a7.156 7.156 0 11.033-14.311l280.503.645a7.156 7.156 0 11-.033 14.312z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("circle", { cx: "134.619", cy: "151.447", r: "37.687", fill: "#3f3d56" }),
        React.createElement("path", { fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : "#6c63ff", d: "M577.08 305.35l-.002-89.167a50.448 50.448 0 00-100.868-2.219h11.103a39.395 39.395 0 0139.307-37.868c.192 0 .386.002.578.004a39.42 39.42 0 0138.783 40.003v89.293c.002.228.002.455 0 .661a26.425 26.425 0 01-26.387 26.074l-.325-.001a26.438 26.438 0 01-26.072-26.777l.06-88.566a13.296 13.296 0 0126.587-.087v67.633a5.549 5.549 0 1011.098 0l-.001-67.721a24.393 24.393 0 00-48.78.084l-.061 88.586a37.534 37.534 0 0037.034 37.944c.155.003.307.003.462.003a37.544 37.544 0 0037.483-37.058c.002-.289.002-.579 0-.822z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M0 567.897H557.106V569.897H0z" }),
        React.createElement("path", { fill: "#2f2e41", d: "M800.122 288.4h77.63v-38.606a39.602 39.602 0 00-11.369-27.876 38.262 38.262 0 00-27.448-11.55c-21.215 0-38.258 16.865-38.801 38.395-.006.353-.012.69-.012 1.031z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M784.451 498.493a11.605 11.605 0 0012.892-13.389l-2.95-18.025h-14.547l-5.052 16.502a11.605 11.605 0 009.657 14.912z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M962.604 713.485l-17.276 2.121-89.106-186.698-50.311 186.698-18.185-5.455 16.972-299.445 80.014-4.243c30.357 35.939 54.201 190.758 77.892 307.022z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M796.152 735.003h-36.247a6.5 6.5 0 01-6.494-6.23 6.5 6.5 0 013.389-5.98l20.847-11.339 12.845-3.425c5.655 6.48 11.063 6.514 16.27 1.285l1.708 11.385a12.456 12.456 0 01-12.318 14.304zM951.936 735.003h-36.247a6.5 6.5 0 01-6.494-6.23 6.5 6.5 0 013.389-5.98l20.847-11.339 12.845-3.425c5.655 6.48 11.063 6.514 16.27 1.285l1.708 11.385a12.456 12.456 0 01-12.318 14.304z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : "#6c63ff", d: "M774.996 471.322l22.428-1.212 13.942-161.846-10.552 1.583a17.857 17.857 0 00-15.115 15.828z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("circle", { cx: "606.793", cy: "84.47", r: "29.702", fill: "#ffb9b9" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M868.952 300.384c-22.476 65.622-42.239 70.206-60.617 43.644 1.619-19.794 8.189-35.83 20.004-47.887 6.86-4.703 2.81-13.639-2.425-23.034l28.49-9.699c.811 11.972 6.054 24.331 14.548 36.976z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: (_e = props.fillColor) !== null && _e !== void 0 ? _e : "#6c63ff", d: "M886.227 409.19c-32.543 22.468-61.816 26.62-81.226 5.456-6.787-28.098-8.188-57.556-4.894-86.667a31.098 31.098 0 0113.953-22.54l14.279-9.298 6.97 22.125 26.975-29.399 20.38 2.46a15.044 15.044 0 0113.15 16.58z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M866.442 227.026l-7.377 23.47-63.78-1.133a39.427 39.427 0 0171.157-22.337z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M879.098 473.268a1.085 1.085 0 00-1.086 1.081l-.267 115.97a1.085 1.085 0 001.081 1.086l93.426.215a1.085 1.085 0 001.087-1.081l.266-115.97a1.085 1.085 0 00-1.08-1.086z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#fff", d: "M882.558 476.744a1.085 1.085 0 00-1.086 1.082l-.25 109.033a1.085 1.085 0 001.08 1.086l86.49.199a1.085 1.085 0 001.086-1.081l.251-109.034a1.085 1.085 0 00-1.081-1.086z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M957.56 524.09l-63.73-.146a1.626 1.626 0 11.007-3.251l63.73.146a1.626 1.626 0 11-.008 3.252zM958.176 493.799l-42.28-.097c-.595-.002-1.077-.73-1.075-1.629s.487-1.624 1.083-1.623l42.28.097c.595.002 1.076.73 1.074 1.629s-.486 1.624-1.082 1.623zM958.155 503.12l-42.28-.097c-.596-.002-1.077-.73-1.075-1.629s.487-1.624 1.082-1.623l42.28.097c.596.002 1.077.73 1.075 1.629s-.487 1.624-1.082 1.623zM957.535 534.604l-63.73-.147a1.626 1.626 0 01.008-3.251l63.73.146a1.626 1.626 0 01-.008 3.252zM957.51 545.117l-63.728-.147a1.626 1.626 0 01.007-3.251l63.73.146a1.626 1.626 0 01-.008 3.252zM957.487 555.63l-63.73-.147a1.626 1.626 0 11.008-3.251l63.729.147a1.626 1.626 0 11-.007 3.251zM957.462 566.143l-63.729-.146a1.626 1.626 0 11.008-3.252l63.729.147a1.626 1.626 0 11-.008 3.251zM957.438 576.656l-63.729-.146a1.626 1.626 0 01.008-3.252l63.729.147a1.626 1.626 0 01-.008 3.251z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("circle", { cx: "674.434", cy: "331.813", r: "8.562", fill: "#3f3d56" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M910.415 481.52a11.605 11.605 0 01-12.892-13.389l2.95-18.025h14.547l5.052 16.503a11.605 11.605 0 01-9.657 14.912z", transform: "translate(-226.395 -164.997)" }),
        React.createElement("path", { fill: (_f = props.fillColor) !== null && _f !== void 0 ? _f : "#6c63ff", d: "M919.87 454.35l-22.429-1.213L883.5 291.292l10.552 1.582a17.857 17.857 0 0115.114 15.829z", transform: "translate(-226.395 -164.997)" })));
}

function WalletIllustration(props) {
    var _a, _b, _c, _d;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: (_a = props.width) !== null && _a !== void 0 ? _a : 200, height: (_b = props.height) !== null && _b !== void 0 ? _b : 200, "data-name": "Layer 1", viewBox: "0 0 574.476 695.655" },
        React.createElement("path", { fill: "#3f3d56", d: "M725.498 262.43v78.692a2.751 2.751 0 01-2.744 2.744h-1.953v409.93a42.775 42.775 0 01-42.772 42.773H421.887a42.764 42.764 0 01-42.76-42.769V358.243h-1.415a1.938 1.938 0 01-1.94-1.929v-44.785a1.94 1.94 0 011.94-1.941h1.414v-16.87H377.7a2.033 2.033 0 01-2.024-2.036v-44.174a2.03 2.03 0 012.024-2.025h1.426v-23.027h-1.45a1.795 1.795 0 01-1.797-1.793v-24.11a1.805 1.805 0 011.797-1.81h1.45v-48.81a42.762 42.762 0 0142.76-42.76H678.03a42.772 42.772 0 0142.772 42.76v114.754h1.953a2.75 2.75 0 012.744 2.744z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#e6e8ec", d: "M207.914 21.933h41.622a3.045 3.045 0 013.046 3.046v3.597a3.045 3.045 0 01-3.046 3.046h-41.622a3.045 3.045 0 01-3.045-3.046V24.98a3.045 3.045 0 013.045-3.046z" }),
        React.createElement("circle", { cx: "265.291", cy: "26.778", r: "5.495", fill: "#e6e8ec" }),
        React.createElement("path", { fill: "#fff", d: "M700.32 153.372v603.975a27.14 27.14 0 01-27.137 27.125H427.99a27.095 27.095 0 01-27.137-27.053V153.396a27.136 27.136 0 0127.137-27.137h36.698v4.708a22.345 22.345 0 0022.344 22.345h124.699a22.345 22.345 0 0022.345-22.345v-4.708h39.106a27.115 27.115 0 0127.137 27.113z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#f2f2f2", d: "M700.32 676.284v81.063a27.14 27.14 0 01-27.137 27.125H427.99a27.095 27.095 0 01-27.137-27.053v-81.135z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M427.182 201.835a3.598 3.598 0 00-3.594 3.594v64.698a3.598 3.598 0 003.594 3.594h64.698a3.598 3.598 0 003.594-3.594v-64.698a3.598 3.598 0 00-3.594-3.594zM518.238 201.835a3.598 3.598 0 00-3.594 3.594v64.698a3.598 3.598 0 003.594 3.594h64.698a3.598 3.598 0 003.594-3.594v-64.698a3.598 3.598 0 00-3.594-3.594zM609.294 201.835a3.598 3.598 0 00-3.595 3.594v64.698a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.595-3.594v-64.698a3.598 3.598 0 00-3.595-3.594zM427.332 301.278a3.598 3.598 0 00-3.594 3.594v64.697a3.598 3.598 0 003.594 3.595h64.697a3.598 3.598 0 003.595-3.595v-64.697a3.598 3.598 0 00-3.595-3.594zM518.388 301.278a3.598 3.598 0 00-3.595 3.594v64.697a3.598 3.598 0 003.595 3.595h64.697a3.598 3.598 0 003.595-3.595v-64.697a3.598 3.598 0 00-3.595-3.594zM609.444 301.278a3.598 3.598 0 00-3.595 3.594v64.697a3.598 3.598 0 003.595 3.595h64.697a3.598 3.598 0 003.594-3.595v-64.697a3.598 3.598 0 00-3.594-3.594zM427.482 400.72a3.598 3.598 0 00-3.595 3.594v64.698a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.595-3.594v-64.698a3.598 3.598 0 00-3.595-3.594zM518.538 400.72a3.598 3.598 0 00-3.595 3.594v64.698a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.594-3.594v-64.698a3.598 3.598 0 00-3.594-3.594zM609.593 400.72a3.598 3.598 0 00-3.594 3.594v64.698a3.598 3.598 0 003.594 3.594h64.698a3.598 3.598 0 003.594-3.594v-64.698a3.598 3.598 0 00-3.594-3.594z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: (_c = props.fillColor) !== null && _c !== void 0 ? _c : "#6c63ff", d: "M427.332 694.255a3.598 3.598 0 00-3.594 3.595v64.697a3.598 3.598 0 003.594 3.594h64.697a3.598 3.598 0 003.595-3.594V697.85a3.598 3.598 0 00-3.595-3.595zM518.388 694.255a3.598 3.598 0 00-3.595 3.595v64.697a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.595-3.594V697.85a3.598 3.598 0 00-3.595-3.595zM609.444 694.255a3.598 3.598 0 00-3.595 3.595v64.697a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.594-3.594V697.85a3.598 3.598 0 00-3.594-3.595z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M427.632 500.163a3.598 3.598 0 00-3.595 3.594v64.698a3.598 3.598 0 003.595 3.594h64.697a3.598 3.598 0 003.594-3.594v-64.698a3.598 3.598 0 00-3.594-3.594z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M791.994 543.19l3.278 16.232a13.69 13.69 0 0016.545 10.617 13.69 13.69 0 008.028-21.261l-9.49-13.343-23.477-64.385 4.286-55.149c-13.474-5.06-27.191-10.727-34.627-.734l9.238 60.343z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: (_d = props.fillColor) !== null && _d !== void 0 ? _d : "#6c63ff", d: "M804.97 554.242a3.152 3.152 0 00-3.148 3.149v56.671a3.152 3.152 0 003.148 3.149h56.672a3.152 3.152 0 003.149-3.149v-56.671a3.152 3.152 0 00-3.149-3.149z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M820.158 333.565c0-.334-.006-.663-.012-1.009-.532-21.061-17.204-37.559-37.957-37.559a37.43 37.43 0 00-26.851 11.298 38.74 38.74 0 00-11.123 27.27v37.766h75.943zM799.248 789.807a7.868 7.868 0 00-3.52-7.717l-22.584-11.082-17.026 2.15-4.021 14.415a7.672 7.672 0 006.71 8.876l31.772.07a7.868 7.868 0 008.67-6.712z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M408.248 457.583L410.867 474.313 411.103 475.846 421.168 540.15 424.278 560.025 425.232 566.122 427.878 585.358 440.211 674.941 461.655 670.08 462.679 566.805 462.855 549.076 463.021 534.088 463.17 520.905 463.766 467.884 408.248 457.583z" }),
        React.createElement("circle", { cx: "477.384", cy: "229.243", r: "28.752", fill: "#ffb9b9" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M758.28 380.534l32.585 16.292a80.126 80.126 0 0110.063-46.482l-28.273-4.313c-.38 11.354-6.125 22.886-14.375 34.503z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M742.466 492.665l57.504 12.46 4.792-37.378c3.119-15.986 8.744-31.621-1.107-44.182l-1.444-12.92a41.717 41.717 0 00-19.349-30.74l-17.395-10.872c-36.056 19.505-34.606 67.687-23 123.632z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { d: "M424.278 560.025L425.232 566.122 427.878 585.358 462.679 566.805 462.855 549.076 463.021 534.088 424.278 560.025z", opacity: "0.2" }),
        React.createElement("path", { d: "M721.01 559.755l2.62 16.73.236 1.534a62.978 62.978 0 0016.012 17.79l36.054 27.269.596-53.022z", opacity: "0.2", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M728.57 660.863l6.708 22.522 86.677-46.217a23.208 23.208 0 005.816-35.414l-47.448-53.981 21.084-47.44-60.378-15.814-6.71 14.376q-21.02 19.622-18.919 45.797a61.64 61.64 0 0024.48 44.113l45.235 34.201z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M721.993 703.844a7.8 7.8 0 007.41-3.976l12.99-23.274-3.213-16.707-14.519-3.059a7.606 7.606 0 00-8.354 7.206l-1.507 31.661a7.8 7.8 0 007.193 8.149z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#ffb9b9", d: "M740.55 549.69l-.518 16.551a13.69 13.69 0 0013.683 14.117 13.69 13.69 0 0012.672-18.865l-6.19-15.158-8.147-68.046 16.772-52.711c-11.962-8.005-24.022-16.656-33.544-8.626l-4.792 60.858z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#e6e6e6", d: "M729.528 424.62c17.655-8.44 31.188-4.134 41.21 11.021l6.498-36.25a19.336 19.336 0 00-10.742-20.88 15.709 15.709 0 00-21.247 8.178z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#2f2e41", d: "M758.634 311.292l7.217 22.96 62.393-1.109a38.57 38.57 0 00-69.61-21.85z", transform: "translate(-312.762 -102.173)" }),
        React.createElement("path", { fill: "#3f3d56", d: "M574.08 695.655L0 695.655 0 693.474 574.476 693.474 574.08 695.655z" })));
}

//@ts-nocheck
const BACKGROUND_COLOR = '#FAFBFC';
const WAVE_COLOR = '#f05776';
const TITLE_FONT_COLOR$1 = '#373A3B';
const GETTING_STARTED_BUTTON_TITLE = 'Get Started';
const LandingPageInternal = (props) => {
    var _a;
    const { width, height, bp } = reactViewportProvider.useViewport();
    const styles = {
        container: {
            flex: 1,
            width: 1 * width,
            height: 1 * height,
        },
        waveContainer: {
            background: BACKGROUND_COLOR,
            textAlign: 'center',
            overflow: 'hidden',
            width: 1 * width,
        },
        bodyContainer: {
            width: 1 * width,
            // height: 0.8 * height,
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 30,
        },
        bodyTitleStyle: {
            fontWeight: 'bold',
            color: TITLE_FONT_COLOR$1,
            lineHeight: bp !== 'xs' && bp !== 'sm' ? 30 : 35,
            alignSelf: 'center',
            textAlign: 'center',
            letterSpacing: 2,
        },
        bodySubtitleStyle: {
            color: 'rgba(99,102,105,1)',
            lineHeight: bp !== 'xs' && bp !== 'sm' ? 40 : 20,
            alignSelf: 'center',
            textAlign: 'center',
            letterSpacing: 2,
            marginTop: 10,
            paddingLeft: 5,
            paddingRight: 5,
        },
        formContainerStyle: {
            width: bp === 'xs' || bp === 'sm' ? 0.7 * width : 0.2 * width,
            height: 90,
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
        },
        buttonFormGroupContainer: {
            flexDirection: bp === 'xs' || bp === 'sm' ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        gettingStartedButtonStyle: {
            width: 100,
            height: 42,
            borderRadius: 5,
            marginLeft: 15,
            alignSelf: 'center',
        },
        gettingStartedContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        getStartedTitleStyle: {
            fontSize: 15,
        },
        illustrationContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: width,
            marginTop: 20,
            height: 150,
        },
    };
    const renderContent = () => {
        return (React.createElement(Content, null,
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.bodyContainer]) },
                renderTitle(),
                renderSubtitle(),
                renderForm(),
                renderIllustration())));
    };
    const renderTitle = () => {
        if (props.bodyTitle) {
            return (React.createElement(TextV, { type: bp === 'xs' || bp === 'sm'
                    ? 'title3'
                    : bp === 'md'
                        ? 'title2'
                        : 'title1', style: reactNative.StyleSheet.flatten([
                    styles.bodyTitleStyle,
                    props.bodyTitleStyle,
                ]) }, props.bodyTitle));
        }
        return null;
    };
    const renderSubtitle = () => {
        if (props.bodySubtitle) {
            return (React.createElement(TextV, { type: bp === 'xs' || bp === 'sm'
                    ? 'caption'
                    : bp === 'md'
                        ? 'caption'
                        : 'title3', style: reactNative.StyleSheet.flatten([
                    styles.bodySubtitleStyle,
                    props.bodySubtitleStyle,
                ]) }, props.bodySubtitle));
        }
        return null;
    };
    const renderForm = () => {
        var _a;
        if (props.loading) {
            if (props.renderLoadingContent) {
                return props.renderLoadingContent();
            }
            return React.createElement(reactLogoSpinnerExt.LogoSpinnerV, Object.assign({}, props));
        }
        if (props.formFields) {
            return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.buttonFormGroupContainer]) },
                React.createElement(reactFormRs.FormV, { components: props.formFields, formContext: props.formContext, containerStyle: reactNative.StyleSheet.flatten([
                        styles.formContainerStyle,
                        props.formContainerStyle,
                    ]), validateForm: props.validateForm, onValidateResult: props.onValidateResult, defaultValues: (_a = props.defaultValues) !== null && _a !== void 0 ? _a : [] }),
                React.createElement(ButtonV, { type: ButtonType.Solid, containerStyle: reactNative.StyleSheet.flatten([
                        styles.gettingStartedContainer,
                    ]), buttonStyle: reactNative.StyleSheet.flatten([
                        styles.gettingStartedButtonStyle,
                        props.signupButtonColor && {
                            backgroundColor: props.signupButtonColor,
                            borderColor: props.signupButtonColor,
                        },
                    ]), title: GETTING_STARTED_BUTTON_TITLE, titleStyle: reactNative.StyleSheet.flatten([styles.getStartedTitleStyle]), onPress: props.onGetStarted })));
        }
        return null;
    };
    const renderIllustration = () => {
        if (props.loading) {
            return null;
        }
        if (bp !== 'xs' && bp !== 'sm' && bp !== 'md') {
            return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'start', style: reactNative.StyleSheet.flatten([styles.illustrationContainer]) },
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(NotificationIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(ShareIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(DocumentIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(JumpIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(StoreIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(NetworkIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(WalletIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor }))));
        }
        if (bp === 'md') {
            return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'start', style: reactNative.StyleSheet.flatten([styles.illustrationContainer]) },
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(NotificationIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(ShareIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(DocumentIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(JumpIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor })),
                React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                    React.createElement(StoreIllustration, { width: 120, height: 120, fillColor: props.illustrationFillColor }))));
        }
        return null;
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.container, props.containerStyle]) },
        React.createElement(Container, null,
            React.createElement(HeaderV, Object.assign({}, props)),
            React.createElement(Container, null, renderContent()),
            React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.waveContainer]) },
                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1440 250" },
                    React.createElement("path", { fill: (_a = props.waveColor) !== null && _a !== void 0 ? _a : WAVE_COLOR, "fill-opacity": "1", d: "M0,160L34.3,181.3C68.6,203,137,245,206,224C274.3,203,343,117,411,80C480,43,549,53,617,96C685.7,139,754,213,823,229.3C891.4,245,960,203,1029,165.3C1097.1,128,1166,96,1234,106.7C1302.9,117,1371,171,1406,197.3L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z" }))))));
};
const LandingPageV = (props) => {
    return (React.createElement(reactViewportProvider.ViewportProvider, null,
        React.createElement(LandingPageInternal, Object.assign({}, props))));
};

exports.LandingPageV = LandingPageV;
