'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

require('rsuite/dist/styles/rsuite-default.css');
var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var IconStack = _interopDefault(require('rsuite/lib/IconStack'));

var ids = require('short-id');
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { name: 'icon' };
const IconV = (props) => {
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([props.containerStyle]) },
        React.createElement(IconStack, { size: props.size }, props.icons && Array.isArray(props.icons) &&
            props.icons.map((icon, index) => {
                const _props = renameKeys(icon, mapper);
                return (React.createElement(Icon, Object.assign({}, _props, { key: ids.generate(), index: index })));
            }))));
};

exports.IconV = IconV;
