//@ts-nocheck
import React from 'react';
import {
  ImageSourcePropType,
  ImageStyle,
  TextStyle,
  ViewStyle,
} from 'react-native';
import {Avatar, IconProps, ImageProps} from 'react-native-elements';

export enum AvatarSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Xlarge = 'xlarge',
}

interface IconObject {
  name: string;
  color: string;
  size: number;
  type?: string;
  iconStyle: ViewStyle;
}

interface AppProps {
  activeOpacity?: number;
  avatarStyle?: ImageStyle;
  containerStyle?: ViewStyle;
  editButton?: IconProps;
  icon?: IconObject;
  iconStyle?: ViewStyle;
  imageProps?: ImageProps;
  onEditPress?(): void;
  onLongPress?(): void;
  onPress?(): void;
  overlayContainerStyle?: ViewStyle;
  placeholderStyle?: ViewStyle;
  rounded?: boolean;
  size?: AvatarSize | number;
  showEditButton?: boolean;
  source?: ImageSourcePropType;
  title?: string;
  titleStyle?: TextStyle;
  renderPlaceholderContent?: any;
  Component?: any;
  ImageComponent?: any;
}

export const AvatarV = (props: AppProps): JSX.Element => {
  const {
    activeOpacity,
    avatarStyle,
    containerStyle,
    editButton,
    icon,
    iconStyle,
    imageProps,
    onEditPress,
    onLongPress,
    onPress,
    overlayContainerStyle,
    placeholderStyle,
    rounded,
    size,
    showEditButton,
    source,
    title,
    titleStyle,
    renderPlaceholderContent,
    Component,
    ImageComponent,
  } = props;
  if (!title) {
    return (
      <Avatar
        activeOpacity={activeOpacity}
        avatarStyle={avatarStyle}
        containerStyle={containerStyle}
        editButton={editButton}
        icon={icon && icon}
        iconStyle={iconStyle}
        imageProps={imageProps}
        onEditPress={onEditPress}
        onLongPress={onLongPress}
        onPress={onPress}
        overlayContainerStyle={overlayContainerStyle}
        placeholderStyle={placeholderStyle}
        rounded={rounded}
        size={size}
        showEditButton={showEditButton}
        source={source}
        titleStyle={titleStyle}
        renderPlaceholderContent={renderPlaceholderContent}
        Component={Component}
        ImageComponent={ImageComponent}
      />
    );
  }
  return (
    <Avatar
      activeOpacity={activeOpacity}
      avatarStyle={avatarStyle}
      containerStyle={containerStyle}
      editButton={editButton}
      imageProps={imageProps}
      onEditPress={onEditPress}
      onLongPress={onLongPress}
      onPress={onPress}
      overlayContainerStyle={overlayContainerStyle}
      placeholderStyle={placeholderStyle}
      rounded={rounded}
      size={size}
      showEditButton={showEditButton}
      source={source}
      title={title}
      titleStyle={titleStyle}
      renderPlaceholderContent={renderPlaceholderContent}
      Component={Component}
      ImageComponent={ImageComponent}
    />
  );
};
